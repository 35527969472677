import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { FaEye, FaEyeSlash } from 'react-icons/fa';

function Profile() {
  const [employeeData, setEmployeeData] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [oldPassword, setOldPassword] = useState('');
  const [newPassword, setNewPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const [successMessage, setSuccessMessage] = useState('');
  
  // Separate visibility states for each password field
  const [showOldPassword, setShowOldPassword] = useState(false);
  const [showNewPassword, setShowNewPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);

  useEffect(() => {
    const employee = JSON.parse(localStorage.getItem('userData'));
    if (employee) {
      setEmployeeData(employee);
    }
  }, []);

  const handlePasswordReset = async (e) => {
    e.preventDefault();
    if (newPassword !== confirmPassword) {
      setErrorMessage("New password and confirm password do not match.");
      return;
    }
    try {
      const token = localStorage.getItem('accessToken');
      const response = await axios.post('https://ramakumaritrades.co.in/web/employee/reset-password', {
        employee_id: employeeData.employee_id,
        oldpassword: oldPassword,
        newpassword: newPassword,
        confirmpassword: confirmPassword,
      }, {
        headers: { Authorization: `Bearer ${token}` },
      });

      if (response.data.success) {
        setSuccessMessage("Password has been successfully reset.");
        setErrorMessage('');
        setIsModalOpen(false);
      } else {
        setErrorMessage("Failed to reset password. Please try again.");
      }
    } catch (error) {
      setErrorMessage("An error occurred during password reset.");
    }
  };

  const toggleModal = () => setIsModalOpen(!isModalOpen);

  return (
    <div className="flex flex-col  h-[calc(100vh-6rem)]   overflow-hidden ">
      <h1 className="text-2xl font-bold mb-6 ">Admin Profile</h1>

      {employeeData ? (
        <div className="flex items-center justify-center bg-white p-2 rounded-lg max-w-full max-h-full space-x-40">      
            <img src={employeeData.profileImage || `https://ramakumaritrades.co.in/storage/userdp/${employeeData.image}`} alt="Profile" className="w-32 h-48 square" />
          <div className="flex flex-col gap-5">
            <p><strong>Name:</strong> {employeeData.employee_name}</p>
            <p><strong>Email:</strong> {employeeData.email}</p>
            <p><strong>Phone:</strong> {employeeData.mobile_number}</p>
            <p><strong>Gender:</strong> {employeeData.gender}</p>
            <p><strong>Role:</strong> {employeeData.role}</p>
            <p><strong>Address:</strong> {employeeData.Address}</p>
            <button onClick={toggleModal} className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 mt-4 rounded">Reset Password</button>
          </div>
        </div>
      ) : (
        <p>Loading employee details...</p>
      )}

      {isModalOpen && (
        <div className="fixed inset-0 bg-gray-800 bg-opacity-50 flex items-center justify-center">
          <div className="bg-white rounded-lg shadow-lg p-6 max-w-sm w-full">
            <h2 className="text-xl font-semibold mb-4">Reset Password</h2>

            <form onSubmit={handlePasswordReset}>
              <div className="mb-4 relative">
                <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="oldPassword">Old Password</label>
                <input
                  type={showOldPassword ? 'text' : 'password'}
                  id="oldPassword"
                  value={oldPassword}
                  onChange={(e) => setOldPassword(e.target.value)}
                  className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700"
                  required
                />
                <span
                  className="absolute right-2 px-2 py-3 cursor-pointer"
                  onClick={() => setShowOldPassword(!showOldPassword)}
                >
                  {showOldPassword ? <FaEyeSlash /> : <FaEye />}
                </span>
              </div>

              <div className="mb-4 relative">
                <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="newPassword">New Password</label>
                <input
                  type={showNewPassword ? 'text' : 'password'}
                  id="newPassword"
                  value={newPassword}
                  onChange={(e) => setNewPassword(e.target.value)}
                  className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700"
                  required
                />
                <span
                  className="absolute right-2 px-2 py-3 cursor-pointer"
                  onClick={() => setShowNewPassword(!showNewPassword)}
                >
                  {showNewPassword ? <FaEyeSlash /> : <FaEye />}
                </span>
              </div>

              <div className="mb-4 relative">
                <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="confirmPassword">Confirm New Password</label>
                <input
                  type={showConfirmPassword ? 'text' : 'password'}
                  id="confirmPassword"
                  value={confirmPassword}
                  onChange={(e) => setConfirmPassword(e.target.value)}
                  className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700"
                  required
                />
                <span
                  className="absolute right-2 px-2 py-3 cursor-pointer"
                  onClick={() => setShowConfirmPassword(!showConfirmPassword)}
                >
                  {showConfirmPassword ? <FaEyeSlash /> : <FaEye />}
                </span>
              </div>

              {errorMessage && <p className="text-red-500 text-sm">{errorMessage}</p>}
              {successMessage && <p className="text-green-500 text-sm">{successMessage}</p>}

              <div className="flex justify-between">
                <button type="submit" className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded">Save Password</button>
                <button onClick={toggleModal} className="bg-red-500 hover:bg-red-700 text-white font-bold py-2 px-4 rounded">Cancel</button>
              </div>
            </form>
          </div>
        </div>
      )}
    </div>
  );
}

export default Profile;
