import React, { useState, useEffect } from 'react';
import { FaEdit, FaTrash } from 'react-icons/fa';
import * as XLSX from 'xlsx'; // For Excel download
import axios from 'axios';

function AreaPage() {
  const [showAddModal, setShowAddModal] = useState(false);
  const [showEditModal, setShowEditModal] = useState(false);
  const [formData, setFormData] = useState({
    area_name: '',
    image: null,
    employee_name: '',
    employee_id: '',
  });
  const [areas, setAreas] = useState([]);
  const [selectedArea, setSelectedArea] = useState(null);
  const [searchTerm, setSearchTerm] = useState('');
  const [csvFile, setCsvFile] = useState(null);

  const token = localStorage.getItem("accessToken");


  const fetchAreas = async () => {
    try {
      const response = await axios.get('https://ramakumaritrades.co.in/web/area/get-all-area', {
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`
        },
      });

      if (response.status !== 200) {
        throw new Error('Failed to fetch areas. Status: ' + response.status);
      }

      const areaData = response.data.data || [];
      setAreas(areaData);
      localStorage.setItem('area_id', JSON.stringify(areaData.map(area => area.area_id))); // Storing area_id in local storage
    } catch (error) {
      console.error('Error fetching areas:', error);
    }
  };
  useEffect(() => {
    fetchAreas();
  }, [token]);




  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value
    }));
  };

  const handleFileChange = (e) => {
    const file = e.target.files[0];
    setFormData((prevData) => ({
      ...prevData,
      image: file
    }));
  };

  const handleSearchChange = (e) => {
    setSearchTerm(e.target.value);
  };

  const handleCsvChange = (e) => {
    setCsvFile(e.target.files[0]);
  };

  const toggleAddModal = () => {
    setShowAddModal(!showAddModal);
  };

  const toggleEditModal = (area) => {
    if (area) {
      setFormData({
        area_name: area.area_name || '',
        image: area.image || null,
        employee_name: area.employee_name || '',
        employee_id: area.employee_id || '',
        // customer_name: area.customer_name || '',
      });
      setSelectedArea(area);
    } else {
      // If area is not provided, reset the form (useful for closing modal)
      setFormData({
        area_name: '',
        image: null,
        employee_name: '',
        employee_id: '',
        // customer_name: '',
      });
    }
    setShowEditModal(!showEditModal);
  };



  const handleAddArea = async (e) => {
    e.preventDefault();
    try {
      const formDataToSend = new FormData();
      formDataToSend.append('area_name', formData.area_name);
      formDataToSend.append('image', formData.image);
      formDataToSend.append('employee_name', formData.employee_name);
      formDataToSend.append('employee_id', formData.employee_id);


      const response = await fetch('https://ramakumaritrades.co.in/web/area/create-area', {
        method: 'POST',
        body: formDataToSend,
        headers: {
          'Authorization': `Bearer ${token}`
        },
      });

      if (!response.ok) {
        throw new Error('Failed to add area. Status: ' + response.status);
      }

      const data = await response.json();
      if (data.success) {
        setAreas([...areas, data.data]);
        toggleAddModal();
      } else {
        console.error('Failed to add area:', data.message);
      }
    } catch (error) {
      console.error('Error adding area:', error);
    }
  };


  // csv file uplode

  const handleUploadCsv = async () => {
    if (!csvFile) {
      console.error("No CSV file selected");
      return;
    }

    try {
      // Prepare FormData to send the CSV file
      const formData = new FormData();
      formData.append("csv_file", csvFile); // Append the CSV file to the form data with the key "csv_file"

      // Ensure the token is present
      const token = localStorage.getItem('accessToken');
      if (!token) {
        console.error("Authorization token is missing.");
        return;
      }

      // Make the API call to upload the CSV file and store data in the database
      const response = await fetch('https://ramakumaritrades.co.in/web/area/bulk-upload-area', {
        method: 'POST',
        body: formData, // Send the FormData object containing the CSV file
        headers: {
          'Authorization': `Bearer ${token}`,
          // No need to set "Content-Type" header, as it will be automatically set by FormData
        }
      });

      // Check if the upload was successful
      if (!response.ok) {
        throw new Error(`Failed to upload CSV file: ${response.statusText}`);
      }

      const data = await response.json(); // Get the server's response

      console.log("Server response:", data);

      // Show success message or perform additional actions
      alert("CSV file uploaded and data stored successfully.");
      fetchAreas();
    } catch (error) {
      console.error("Error during CSV upload:", error.message);
    }
  };


  // Function to handle the Edit button click for area

  const openEditModal = (area) => {
    setFormData({
      area_name: area.area_name || '',
      // customer_name: area.customer_name || '',
      employee_name: area.employee_name || '',
      employee_id: area.employee_id || '',
      image: area.image || null,
    });
    setSelectedArea(area);
    toggleEditModal(true);
  };

  // Update handleEditArea function
  const handleEditArea = async (e) => {
    e.preventDefault();
    try {
      const formDataToSend = new FormData();

      if (formData.area_name) formDataToSend.append('area_name', formData.area_name);
      if (formData.employee_name) formDataToSend.append('employee_name', formData.employee_name);
      if (formData.employee_id) formDataToSend.append('employee_id', formData.employee_id);
      if (formData.image) formDataToSend.append('image', formData.image);

      formDataToSend.append('area_id', selectedArea.area_id);

      const response = await fetch("https://ramakumaritrades.co.in/web/area/update-area", {
        method: 'PUT',
        body: formDataToSend,
        headers: {
          'Authorization': `Bearer ${token}`
        },
      });

      if (!response.ok) {
        throw new Error('Failed to update area. Status: ' + response.status);
      }

      const data = await response.json();
      if (data.success) {
        setAreas(areas.map(area =>
          area.area_id === selectedArea.area_id
            ? {
              ...area,
              area_name: formData.area_name || area.area_name,
              employee_name: formData.employee_name || area.employee_name,
              employee_id: formData.employee_id || area.employee_id,
              image: formData.image ? URL.createObjectURL(formData.image) : area.image, // Update to the new image URL
            }
            : area
        ));
        toggleEditModal(false); // Close the modal
        fetchAreas();
      } else {
        console.error('Failed to update area:', data.message);
      }
    } catch (error) {
      console.error('Error updating area:', error);
    }
  };


  // delete


  const handleDeleteArea = async (area_id) => {
    console.log(`Deleting brand with ID: ${area_id}`);

    if (!area_id) {
      console.error('Invalid brand_id:', area_id);
      return;
    }

    if (window.confirm('Are you sure you want to delete this area?')) {
      try {
        const response = await fetch("https://ramakumaritrades.co.in/web/area/delete-area-by-id", {
          method: 'DELETE',
          body: JSON.stringify({
            area_id: area_id,
          }),
          headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}` // Ensure the token is also valid
          },
        });


        if (response.ok) {
          const data = await response.json();
          if (data.success) {
            setAreas(areas.filter(area => area.area_id !== area_id));
          } else {
            console.error('Failed to delete area:', data.message);
          }
        } else {
          console.error('Failed to delete area. Status:', response.status);
        }
      } catch (error) {
        console.error('Error deleting area:', error);
      }
    }
  };

  const handleDownloadExcel = () => {
    const dataToExport = areas.map(area => ({
      'Area ID': area.area_id,
      'Area Name': area.area_name,
      'Area Image': area.area_image,
      'Employee Name': area.employee_name,
    }));

    const worksheet = XLSX.utils.json_to_sheet(dataToExport);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, 'Areas');
    XLSX.writeFile(workbook, 'areas.xlsx');
  };

  const filteredAreas = areas.filter(area =>
    area.area_name.toLowerCase().includes(searchTerm.toLowerCase())
  );


  const fetchAreasAndEmployees = async () => {
    try {
      // Fetch areas
      const areaResponse = await axios.get('https://ramakumaritrades.co.in/web/area/get-all-area', {
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
      });

      if (areaResponse.status !== 200) {
        throw new Error('Failed to fetch areas. Status: ' + areaResponse.status);
      }

      const areasData = areaResponse.data.data || [];
      console.log("Areas Data:", areasData);

      // Fetch employees
      const employeeResponse = await axios.get('https://ramakumaritrades.co.in/web/employee/get-all-employee', {
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
      });

      if (employeeResponse.status !== 200) {
        throw new Error('Failed to fetch employees. Status: ' + employeeResponse.status);
      }

      const employeesData = employeeResponse.data.data || [];
      console.log("Employees Data:", employeesData);

      const updatedAreas = areasData.map((area) => {
        const matchingEmployee = employeesData.find(
          (employee) =>
            employee.area_name === area.area_name &&
            employee.area_id === area.area_id
        );

        // Add employee details to the area
        return {
          ...area,
          employee_id: matchingEmployee ? matchingEmployee.employee_id : 'N/A',
          employee_name: matchingEmployee ? matchingEmployee.employee_name : 'N/A',
        };
      });


      setAreas(updatedAreas); // Update the state with mapped areas
    } catch (error) {
      console.error('Error fetching areas and employees:', error);
    }
  };

  useEffect(() => {
    fetchAreasAndEmployees();
  }, [token]);


  return (
    <div className="flex flex-col h-[calc(100vh-6rem)] overflow-hidden">
    <div className="flex  justify-between">
      <div className="flex mb-2 gap-2">
        <input
          type="file"
          accept=".csv"
          onChange={handleCsvChange}
          className="border border-gray-300 rounded px-1 py-1 w-64"
        />
        <button
          onClick={handleUploadCsv}
          className="bg-blue-600 text-white px-2 py-2 rounded ml-2"
        >
          Upload Areas
        </button>
      </div>
      <div>
        <button
          onClick={toggleAddModal}
          className="bg-blue-600 text-white px-2 py-2 rounded"
        >
          Add Area
        </button>
      </div>

      <button
        onClick={handleDownloadExcel}
        className="bg-blue-600 text-white px-2 py-2 rounded"
      >
        Download Excel
      </button>

    </div>
  
      <div className="flex items-center mb-2 border border-gray-300 w-1/2 rounded">
        <input
          type="text"
          value={searchTerm}
          onChange={handleSearchChange}
         placeholder="Search by area name"
          className="px-2 py-2 w-96"
        />
      </div>
  
     
  
    <div className="w-full overflow-x-auto h-[calc(100vh-4rem)] overflow-y-auto">
      <table className="table-auto w-full min-w-[1000px]">
        <thead>
          <tr className="bg-gray-100 text-center">
            <th className="border-b p-3">S.NO</th>
            <th className="border-b p-3">Area Id</th>
            <th className="border-b p-3">Area Name</th>
            <th className="border-b p-3">Actions</th>
          </tr>
        </thead>
        <tbody>
          {areas.map((area, index) => (
            <tr key={area.area_id}>
              <td className="border-b p-2 text-center">{index + 1}</td>
              <td className="border-b p-2 text-center">{area.area_id}</td>
              <td className="border-b p-2 text-center">{area.area_name}</td>
              <td className="border-b p-2 text-center">
                <button
                  onClick={() => toggleEditModal(area)}
                  className="text-blue-600 hover:text-blue-800 mx-2"
                >
                  <FaEdit size={16} />
                </button>
                <button
                  onClick={() => handleDeleteArea(area.area_id)}
                  className="text-red-600 hover:text-red-800 mx-2"
                >
                  <FaTrash size={16} />
                </button>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  
  


      {/* Add Brand Modal */}
      {showAddModal && (
        <div className="fixed inset-0 bg-gray-800 bg-opacity-50 flex justify-center items-center">
          <div className="bg-white p-6 rounded">
            <h2 className="text-xl mb-4">Add New Arae</h2>
            <form onSubmit={handleAddArea}>
              <div className="grid grid-cols-2 gap-4">
                <div>
                  <label>Area Name:</label>
                  <input
                    type="text"
                    name="area_name"
                    value={formData.area_name}
                    onChange={handleInputChange}
                    required
                    className="border border-gray-300 rounded px-2 py-1 w-full"
                  />
                </div>
                <div>
                  <label>Area Image:</label>
                  <input
                    type="file"
                    name="image"
                    onChange={handleFileChange}
                    required
                    className="border border-gray-300 rounded px-2 py-1 w-full"
                  />
                </div>
              </div>


              <div className="mt-4">
                <button
                  type="submit"
                  className="bg-blue-600 text-white px-4 py-2 rounded"
                >
                  Add Area
                </button>
                <button
                  type="button"
                  onClick={toggleAddModal}
                  className="bg-gray-400 text-white px-4 py-2 rounded ml-4"
                >
                  Cancel
                </button>
              </div>
            </form>
          </div>
        </div>
      )}

      {/*  Edit modal */}
      {showEditModal && (
        <div className="fixed inset-0 bg-gray-800 bg-opacity-50 flex justify-center items-center">
          <div className="bg-white p-6 rounded">
            <h2 className="text-xl mb-4">Edit Area</h2>
            <form onSubmit={handleEditArea}>
              <div className="grid grid-cols-2 gap-4">
                <div>
                  <label>Area Name:</label>
                  <input
                    type="text"
                    name="area_name"
                    value={formData.area_name}
                    onChange={handleInputChange}
                    className="border border-gray-300 rounded px-2 py-1 w-full"
                  />
                </div>
                <div>
                  <label>Area Image:</label>
                  <input
                    type="file"
                    name="image"
                    onChange={handleFileChange}
                    className="border border-gray-300 rounded px-2 py-1 w-full"
                  />
                </div>
              </div>

              <div className="mt-4">
                <button
                  type="submit"
                  className="bg-blue-600 text-white px-4 py-2 rounded"
                >
                  Save Changes
                </button>
                <button
                  type="button"
                  onClick={() => toggleEditModal(false)}
                  className="bg-gray-400 text-white px-4 py-2 rounded ml-4"
                >
                  Cancel
                </button>
              </div>
            </form>
          </div>
        </div>
      )}

    </div>
  );
}

export default AreaPage;
