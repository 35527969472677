import React, { useState, useEffect } from 'react';
import { FaEdit, FaTrash } from 'react-icons/fa';
import * as XLSX from 'xlsx'; // For Excel download
import axios from 'axios';

function BrandPage() {
  const [showAddModal, setShowAddModal] = useState(false);
  const [showEditModal, setShowEditModal] = useState(false);
  const [formData, setFormData] = useState({
    name: '',
    description: ''
  });
  const [brands, setBrands] = useState([]);
  const [selectedBrand, setSelectedBrand] = useState(null);
  const [searchTerm, setSearchTerm] = useState('');
  const [csvFile, setCsvFile] = useState(null);

  const token = localStorage.getItem("accessToken");


  const fetchBrands = async () => {
    try {
      const response = await axios.get('https://ramakumaritrades.co.in/web/brand/get-all-brand', {
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`
        },
      });

      if (response.status !== 200) {
        throw new Error('Failed to fetch brands. Status: ' + response.status);
      }

      console.log("data", response.data);
      const data = response.data;
      setBrands(data.data || []);
      localStorage.setItem('brand_id', JSON.stringify(data.data.map(brand => brand.brand_id)));
    } catch (error) {
      console.error('Error fetching brands:', error);
    }
  };

  useEffect(() => {
    fetchBrands();
  }, [token]);

  // Input change handler
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value
    }));
  };

  // File change handler
  const handleFileChange = (e) => {
    const file = e.target.files[0];
    setFormData(prevData => ({
      ...prevData,
      image: file
    }));
  };


  const handleSearchChange = (e) => {
    setSearchTerm(e.target.value);
  };

  const handleCsvChange = (e) => {
    setCsvFile(e.target.files[0]);
  };

  const toggleAddModal = () => {
    setShowAddModal(!showAddModal);
  };

  const toggleEditModal = (brand) => {
    setSelectedBrand(brand);
    setFormData(brand || {
      name: '',
      description: ''
    });
    setShowEditModal(!showEditModal);
  };

  const handleAddBrand = async (e) => {
    e.preventDefault();
    try {
      const formDataToSend = new FormData();
      formDataToSend.append('brand_name', formData.name);
      formDataToSend.append('description', formData.description);

      const response = await fetch('https://ramakumaritrades.co.in/web/brand/create-brand', {
        method: 'POST',
        body: formDataToSend,
        headers: {
          'Authorization': `Bearer ${token}`
        },
      });

      if (!response.ok) {
        throw new Error('Failed to add brand. Status: ' + response.status);
      }

      const data = await response.json();
      if (data.success) {
        setBrands([...brands, data.data]);
        toggleAddModal();
      } else {
        console.error('Failed to add brand:', data.message);
      }
    } catch (error) {
      // console.error('Error adding brand:', error);
      alert("The Brand Name already exists. Please create a new one.");

    }
  };

  // csv file uplode

  const handleUploadCsv = async () => {
    if (!csvFile) {
      console.error("No CSV file selected");
      return;
    }

    try {
      // Prepare FormData to send the CSV file
      const formData = new FormData();
      formData.append("csv_file", csvFile); // Append the CSV file to the form data with the key "csv_file"

      // Ensure the token is present
      const token = localStorage.getItem('accessToken');
      if (!token) {
        console.error("Authorization token is missing.");
        return;
      }

      // Make the API call to upload the CSV file and store data in the database
      const response = await fetch('https://ramakumaritrades.co.in/web/brand/bulk-upload-brand', {
        method: 'POST',
        body: formData, // Send the FormData object containing the CSV file
        headers: {
          'Authorization': `Bearer ${token}`,
          // No need to set "Content-Type" header, as it will be automatically set by FormData
        }
      });

      // Check if the upload was successful
      if (!response.ok) {
        throw new Error(`Failed to upload CSV file: ${response.statusText}`);
      }

      const data = await response.json(); // Get the server's response

      console.log("Server response:", data);

      // Show success message or perform additional actions
      alert("CSV file uploaded and data stored successfully.");
      fetchBrands();
    } catch (error) {
      console.error("Error during CSV upload:", error.message);
    }
  };



  // Edit brand

  const handleEditBrand = async (e) => {
    e.preventDefault();
    try {
      const formDataToSend = new FormData();
      if (formData.name) formDataToSend.append('brand_name', formData.name);
      if (formData.description) formDataToSend.append('description', formData.description);

      // Always include the brand_id in the update
      formDataToSend.append('brand_id', selectedBrand.brand_id);

      const response = await fetch("https://ramakumaritrades.co.in/web/brand/update-brand", {
        method: 'PUT',
        body: formDataToSend, // Send formDataToSend here
        headers: {
          'Authorization': `Bearer ${token}` // Authorization header for authentication
        },
      });

      if (!response.ok) {
        throw new Error('Failed to update brand. Status: ' + response.status);
      }

      const data = await response.json();
      if (data.success) {
        // Update state to reflect the changes
        setBrands(brands.map(brand =>
          brand.brand_id === selectedBrand.brand_id
            ? {
              ...brand,
              brand_name: formData.name || brand.brand_name, // Update name only if changed
              description: formData.description || brand.description // Update description only if changed
            }
            : brand
        ));


        toggleEditModal(null); // Close the modal
        fetchBrands();

      } else {
        console.error('Failed to update brand:', data.message);
      }
    } catch (error) {
      console.error('Error updating brand:', error);
    }
  };

  // delete

  const handleDeleteBrand = async (brand_id) => {
    console.log(`Deleting brand with ID: ${brand_id}`);

    if (!brand_id) {
      console.error('Invalid brand_id:', brand_id);
      return;
    }

    if (window.confirm('Are you sure you want to delete this brand?')) {
      try {
        const response = await fetch("https://ramakumaritrades.co.in/web/brand/delete-brand-by-id", {
          method: 'DELETE',
          body: JSON.stringify({
            brand_id: brand_id,
          }),
          headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}` // Ensure the token is also valid
          },
        });

        // Check for successful response
        if (response.ok) {
          const data = await response.json();
          if (data.success) {
            // Update the state to reflect the deleted brand
            setBrands(prevBrands => prevBrands.filter(brand => brand.brand_id !== brand_id));
          } else {
            console.error('Failed to delete brand:', data.message);
          }
        } else if (response.status === 404) {
          console.error('Brand not found. Please check the brand_id or the backend route.');
        } else {
          console.error('Failed to delete brand. Status:', response.status);
        }
      } catch (error) {
        console.error('Error deleting brand:', error);
      }
    }
  };


  // excel sheet

  const handleDownloadExcel = () => {
    const dataToExport = brands.map(brand => ({
      'Brand Id': brand.brand_id,
      'Brand Name': brand.brand_name,
      'Description': brand.description,
  
    }));

    const worksheet = XLSX.utils.json_to_sheet(dataToExport);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, 'Brands');
    XLSX.writeFile(workbook, 'brands.xlsx');
  };

  // const filteredBrands = brands.filter(brand =>
  //   brand.brand_name.toLowerCase().includes(searchTerm.toLowerCase())
  // );

  const filteredBrands = brands.filter(brand =>
    brand.brand_name && brand.brand_name.toLowerCase().includes(searchTerm.toLowerCase())
  );


  return (
    <div className="flex flex-col  h-[calc(100vh-6rem)]   overflow-hidden ">
      {/* Top Input Section */}
      <div className="flex flex-wrap mb-2 justify-between gap-2">
        <div className="flex gap-2">
          <input
            type="file"
            accept=".csv"
            onChange={handleCsvChange}
            className="border border-gray-300 rounded px-1 py-1 w-64"
          />
          <button
            onClick={handleUploadCsv}
            className="bg-blue-600 text-white px-3 py-2 rounded"
          >
            Upload Brands
          </button>
        </div>
        <button
          onClick={toggleAddModal}
          className="bg-blue-600 text-white px-2 py-2 rounded"
        >
          Add Brand
        </button>

        <button
          onClick={handleDownloadExcel}
          className="bg-blue-600 text-white px-2 py-2 rounded"
        >
          Download Excel
        </button>


      </div>
  
      {/* Search and Download Section */}
        <div className="flex items-center mb-2 border border-gray-300 rounded w-1/2">
          <input
            type="text"
            value={searchTerm}
            onChange={handleSearchChange}
            placeholder="Search by brand name"
            className="px-2 py-2 w-full "
          />
        </div>
       
  


 {/* Table Container */}
 <div className="w-full overflow-x-auto h-[calc(100vh-4rem)] overflow-y-auto">
 <table className="table-auto w-full min-w-[1000px]">
      <thead>
        <tr className="bg-gray-100 text-center ">
          <th className="border-b p-3 text-center">S.NO</th>
          <th className="border-b p-3 text-center">Brand Id</th>
          <th className="border-b p-3 text-center">Name</th>
          <th className="border-b p-3 text-center">Description</th>
          <th className="border-b p-3 text-center">Actions</th>
        </tr>
      </thead>
      <tbody>
        {filteredBrands.map((brand, index) => (
          <tr key={brand.brand_id}>
            <td className="border-b p-2 text-center">{index + 1}</td>
            <td className="border-b p-2 text-center">{brand.brand_id}</td>
            <td className="border-b p-2 text-center">{brand.brand_name}</td>
            <td className="border-b p-2 text-center">{brand.description}</td>
            <td className="border-b p-2 text-center  ">
              <button
                onClick={() => toggleEditModal(brand)}
                className="text-blue-600 hover:text-blue-800 mx-2"
                >
                <FaEdit size={16} />
              </button>
              <button
                onClick={() => handleDeleteBrand(brand.brand_id)}
                className="text-red-600 hover:text-red-800 mx-2"
                >
                <FaTrash size={16} />
              </button>
            </td>
          </tr>
        ))}
      </tbody>
    </table>
  </div>



        {/* Modals */}
        {showAddModal && (
          <div className="fixed inset-0 bg-gray-800 bg-opacity-50 flex justify-center items-center">
            <div className="bg-white p-4 sm:p-6 rounded w-11/12 max-w-md md:max-w-lg">
              <h2 className="text-xl mb-4">Add New Brand</h2>
              <form onSubmit={handleAddBrand}>
                <div className="grid grid-cols-1 gap-4">
                  <div>
                    <label>Brand Name:</label>
                    <input
                      type="text"
                      name="name"
                      value={formData.name}
                      onChange={handleInputChange}
                      required
                      className="border border-gray-300 rounded px-2 py-1 w-full"
                    />
                  </div>
                  <div>
                    <label>Description:</label>
                    <textarea
                      name="description"
                      value={formData.description}
                      onChange={handleInputChange}
                      className="border border-gray-300 rounded px-2 py-1 w-full"
                    />
                  </div>
                </div>
                <div className="mt-4 flex justify-end gap-4">
                  <button
                    type="submit"
                    className="bg-blue-600 text-white px-4 py-2 rounded"
                  >
                    Add Brand
                  </button>
                  <button
                    type="button"
                    onClick={toggleAddModal}
                    className="bg-gray-400 text-white px-4 py-2 rounded"
                  >
                    Cancel
                  </button>
                </div>
              </form>
            </div>
          </div>
        )}
    
{/* Edit Modal */}
{showEditModal && (
  <div className="fixed inset-0 bg-gray-800 bg-opacity-50 flex justify-center items-center">
    <div className="bg-white p-4 sm:p-6 rounded w-11/12 max-w-md md:max-w-lg">
      <h2 className="text-xl mb-4">Edit Brand</h2>
      <form onSubmit={handleEditBrand}>
        <div className="grid grid-cols-1 gap-4">
          <div>
            <label>Brand Name:</label>
            <input
              type="text"
              name="name"
              value={formData.name}
              onChange={handleInputChange}
              required
              className="border border-gray-300 rounded px-2 py-1 w-full"
            />
          </div>
          <div>
            <label>Description:</label>
            <textarea
              name="description"
              value={formData.description}
              onChange={handleInputChange}
              className="border border-gray-300 rounded px-2 py-1 w-full"
            />
          </div>
        </div>
        <div className="mt-4 flex justify-end gap-4">
          <button
            type="submit"
            className="bg-blue-600 text-white px-4 py-2 rounded"
          >
            Save Changes
          </button>
          <button
            type="button"
            onClick={toggleEditModal}
            className="bg-gray-400 text-white px-4 py-2 rounded"
          >
            Cancel
          </button>
        </div>
      </form>
    </div>
  </div>
)}



</div>

    
  );
  
  
}

export default BrandPage;











