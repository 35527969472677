import React, { useState } from 'react';
import OrderList from './orders';
import Invoice from './invoice';

const MainInvoice = () => {
  const [selectedOrder, setSelectedOrder] = useState(null);

  // Function to handle order selection
  const handleSelectOrder = (order) => {
    console.log('Order selected:', order);
    setSelectedOrder(order);
  };

  // Function to close the invoice view
  const handleCloseInvoice = () => {
    setSelectedOrder(null);
  };

  return (
    <div className="p-6">
      {/* Conditionally render Invoice or OrderList */}
      {selectedOrder ? (
        // <invoice order={selectedOrder} onClose={handleCloseInvoice} />
        <Invoice order={selectedOrder} onClose={handleCloseInvoice}/>
      ) : (
        <OrderList onSelectOrder={handleSelectOrder} /> // Pass function as a prop
      )}
    </div>
  );
};

export default MainInvoice;
