import React, { useState } from 'react';
import { BrowserRouter as Router, Route, Routes, Navigate } from 'react-router-dom';
import Navbar from './components/navbar';
import Sidebar from './components/sidebar';
import Login from './pages/login';
import Dashboard from './pages/dashboard';
import Brands from './pages/brands';
import Customers from './pages/customars';
import Products from './pages/products';
import Employee from './pages/employee';
import Orders from './pages/orders';
import User from './pages/area';
import Cart from './pages/cart';
import Logout from './pages/profile';
import Invoice from './pages/invoice';
import Maininvoice from './pages/maininvoice';

const App = () => {
  const [isAuthenticated, setIsAuthenticated] = useState(() => !!localStorage.getItem('accessToken'));
  const [isSidebarCollapsed, setIsSidebarCollapsed] = useState(false);

  const handleLogout = () => {
    setIsAuthenticated(false);
    localStorage.removeItem('accessToken');
    localStorage.removeItem('refreshToken');
  };

  const toggleSidebar = () => {
    setIsSidebarCollapsed((prev) => !prev);
  };

  return (
    <Router>
      {!isAuthenticated ? (
        <Login setIsAuthenticated={setIsAuthenticated} />
      ) : (
        <div className="flex">
          <Sidebar isCollapsed={isSidebarCollapsed} />
          <div className="flex-grow">
            <Navbar onLogout={handleLogout} toggleSidebar={toggleSidebar} />
            <div className="p-4">
              <Routes>
                <Route path="/dashboard" element={<Dashboard />} />
                <Route path="/brands" element={<Brands />} />
                <Route path="/customers" element={<Customers />} />
                <Route path="/products" element={<Products />} />
                <Route path="/employee" element={<Employee />} />
                <Route path="/orders" element={<Orders />} />
                <Route path="/user" element={<User />} />
                <Route path="/cart" element={<Cart />} />
                <Route path="/logout" element={<Logout />} />
                <Route path="/invoice/:order_id" element={<Invoice />} />
                <Route path="/" element={<Maininvoice />} />
                {/* Redirect unmatched routes */}
                <Route path="*" element={<Navigate to="/dashboard" />} />
              </Routes>
            </div>
          </div>
        </div>
      )}
    </Router>
  );
};

export default App;
