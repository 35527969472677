import React, { useState } from 'react';
import {
  FaBars,
  FaTachometerAlt,
  FaTags,
  FaUsers,
  FaShoppingCart,
  FaUserTie ,
  FaUserCircle,
  FaMapMarkerAlt,
  FaTruckLoading ,
  FaBoxes 
  
} from 'react-icons/fa';
import { CiDeliveryTruck } from "react-icons/ci";
import { Link, useLocation } from 'react-router-dom';

const Sidebar = () => {
  const location = useLocation();
  const [isCollapsed, setIsCollapsed] = useState(false);
  const [isProductsDropdownOpen, setIsProductsDropdownOpen] = useState(false);

  const toggleSidebar = () => {
    setIsCollapsed(!isCollapsed);
  };

  const toggleProductsDropdown = () => {
    setIsProductsDropdownOpen(!isProductsDropdownOpen);
  };

  const getLinkClasses = (path) =>
    location.pathname === path
      ? 'flex items-center space-x-2 bg-white text-blue-700 w-full px-2 py-2 rounded-lg'
      : 'flex items-center space-x-2 hover:text-gray-300';

      return (
        <div
          className={`bg-blue-700 text-white flex-shrink-0 p-2 overflow-y-auto transition-all duration-300 ${
            isCollapsed ? 'w-16' : 'w-48'
          } min-h-screen md:min-h-[80vh] sm:min-h-full max-h-screen`}
        >
          {/* Sidebar Header */}
          <div
            className="flex justify-center items-center mb-8 cursor-pointer"
            onClick={toggleSidebar}
          >
            {isCollapsed ? (
              <FaBars className="text-2xl" />
            ) : (
              <h2 className="text-2xl font-bold">Admin</h2>
            )}
          </div>
      
          {/* Sidebar Menu */}
          <ul className={`${isCollapsed ? 'space-y-4' : ''}`}>
            <li className="mb-6">
              <Link to="/dashboard" className={getLinkClasses('/dashboard')}>
                <FaTachometerAlt className="text-xl" />
                {!isCollapsed && <span>Dashboard</span>}
              </Link>
            </li>
            <li className="mb-6">
              <Link to="/brands" className={getLinkClasses('/brands')}>
                <FaTags className="text-xl" />
                {!isCollapsed && <span>Brands</span>}
              </Link>
            </li>
            <li className="mb-6">
              <Link to="/customers" className={getLinkClasses('/customers')}>
                <FaUsers className="text-xl" />
                {!isCollapsed && <span>Customers</span>}
              </Link>
            </li>
      
            <li className="mb-6">
              <Link to="/products" className={getLinkClasses('/products')}>
                <FaBoxes  className="text-xl" />
                {!isCollapsed && <span>Products</span>}
              </Link>
            </li>
      
            <li className="mb-6">
              <Link to="/cart" className={getLinkClasses('/cart')}>
                <FaShoppingCart className="text-xl" />
                {!isCollapsed && <span>Cart Details</span>}
              </Link>
            </li>
      
            <li className="mb-6">
              <Link to="/employee" className={getLinkClasses('/employee')}>
                <FaUserTie  className="text-xl" />
                {!isCollapsed && <span>Employee</span>}
              </Link>
            </li>
            <li className="mb-6">
              <Link to="/orders" className={getLinkClasses('/orders')}>
                <FaTruckLoading     className="text-xl" />
                {!isCollapsed && <span>Orders</span>}
              </Link>
            </li>
            <li className="mb-6">
              <Link to="/user" className={getLinkClasses('/user')}>
                <FaMapMarkerAlt className="text-xl" />
                {!isCollapsed && <span>Area</span>}
              </Link>
            </li>
            <li className="mb-6">
              <Link to="/logout" className={getLinkClasses('/logout')}>
                <FaUserCircle className="text-xl" />
                {!isCollapsed && <span>Profile</span>}
              </Link>
            </li>
          </ul>
        </div>
      );
    }
      export default Sidebar;
      
      