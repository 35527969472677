import React, { useState, useEffect } from 'react';
import { FaEdit, FaTrashAlt } from "react-icons/fa";
import axios from 'axios';

function Cart() {

  const initialFormData = {
    customer_name: "",
    customer_address: "",
    product_name: "",
    delivery_date: "",
    quantity: "",
    units: "",
    sale_price: "",
    discount: "",
    // description: "",
    total_price: "",
    brand_name: "",

  };
  const [order, setorder] = useState([]);
  const [showAddModal, setShowAddModal] = useState(false);
  const [formData, setFormData] = useState(initialFormData);
  const [itemList, setItemList] = useState([]);
  const [products, setProducts] = useState([]);
  const [searchResults, setSearchResults] = useState([]);
  const [customers, setCustomers] = useState([]);
  const [employeeData, setEmployeeData] = useState(null);
  const [cartItems, setCartItems] = useState([]);
  const [selectedCart, setSelectedCart] = useState(null);
  const [showEditModal, setShowEditModal] = useState(false);
  const [showResults, setShowResults] = useState(false);
  const [brands, setBrands] = useState([]);
  const [selectedBrand, setSelectedBrand] = useState('');
  const [filteredProducts, setFilteredProducts] = useState([]);
  const [showBrandDropdown, setShowBrandDropdown] = useState(false);
  const [showProductDropdown, setShowProductDropdown] = useState(false);
  const [allProducts, setAllProducts] = useState([])
  const [showAllProductsModal, setShowAllProductsModal] = useState(false);
  const [selectedProduct, setSelectedProduct] = useState(null);
  const [description, setDescription] = useState(null);
  const [orders, setorders] = useState("")





  const token = localStorage.getItem('accessToken');


  // Handle product search
  const handleItemSearch = (e) => {
    const query = e.target.value.toLowerCase();
    setFormData({ ...formData, product_name: query });

    // Filter products based on the search query
    const filteredProducts = products.filter(product =>
      product.product_name.toLowerCase().includes(query)  // Use 'products' instead of 'allProducts'
    );
    setSearchResults(filteredProducts);
  };

  // Handle focus event to show all products
  const handleFocus = () => {
    setSearchResults(products);  // Show all products on focus (use 'products' here)
  };


  useEffect(() => {
    if (selectedBrand) {
      const filtered = products.filter(product => product.brand_name === selectedBrand);
      setFilteredProducts(filtered);
    } else {
      setFilteredProducts([]);
    }
  }, [selectedBrand, products]);

  useEffect(() => {
    fetchBrands();
    fetchProducts();
  }, []);


  // Handle Input Changes
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    const updatedFormData = { ...formData, [name]: value };

    // Quantity and Rate Calculation
    if (name === 'quantity' || name === 'sale_price') {
      const quantity = parseFloat(updatedFormData.quantity) || 0;
      const SalePrice = parseFloat(updatedFormData.sale_price) || 0;
      updatedFormData.total_price = quantity * SalePrice;
    }

    // Discount Calculation
    if (name === 'discount') {
      const discount = parseFloat(value) || 0;
      const baseAmount = (parseFloat(formData.quantity) || 0) * (parseFloat(formData.sale_price) || 0);
      updatedFormData.total_price = baseAmount - (baseAmount * discount) / 100;
    }

    setFormData(updatedFormData);
  };

  // Add Item to List
  const addItemToList = (item) => {
    setItemList((prevList) => [...prevList, item]);
  };

  const handleAddMore = () => {
    // Add the current form data to the list
    addItemToList(formData);
    // Reset form data to initial state
    setFormData(initialFormData);
  };

  const handleBrandClick = (brandName) => {
    setSelectedBrand(brandName);
    setShowBrandDropdown(false);
    setFilteredProducts(
      allProducts.filter((product) => product.brand_name === brandName)
    );
  };

  const handleBrandSelection = (brand) => {
    setSelectedBrand(brand.brand_name); // Update selected brand
    const relatedProducts = products.filter(product => product.brand_id === brand.brand_id);
    setFilteredProducts(relatedProducts); // Update filtered products

    // Clear product-related fields
    setFormData((prevState) => ({
      ...prevState,
      product_name: "", // Clear product name
      units: "",        // Clear units
      purchase_price: "", // Clear purchase price
      sale_price: "",    // Clear sale price
    }));
  };


  const toggleAddModal = () => {
    setShowAddModal(!showAddModal);
  };



  const toggleAllProductsModal = () => {
    setShowAddModal(false);
    setShowAllProductsModal(true);
  };

  const handleDoneInAllProducts = () => {
    setShowAllProductsModal(false);
    setShowAddModal(true);
    resetForm(); // Reset form on modal close

  };



  // Toggle function for the edit modal
  const toggleEditModal = () => {
    setShowEditModal(!showEditModal);
  };

  const handleCartSelection = (cartItem) => {
    setSelectedCart(cartItem); // Set selected cart item
  };



  // Helper function to reset the form
  const resetForm = () => {
    setFormData({
      customer_name: "",
      customer_address: "",
      product_name: "",
      quantity: "",
      sale_price: "",
      discount: "",
      total_price: "",
      units: "",
      description: "",
      purchase_price: "",
    });
    setSelectedBrand("");
    setShowBrandDropdown(false);
    setShowProductDropdown(false);
    setFilteredProducts([]);
  };


  const fetchProducts = async () => {
    try {
      const response = await axios.get('https://ramakumaritrades.co.in/web/product/get-all-product', {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      if (response.status === 200) {
        setProducts(response.data?.data || []);  // Set products to the fetched data
      } else {
        throw new Error(`Failed to fetch products. Status: ${response.status}`);
      }
    } catch (error) {
      console.error('Error fetching products:', error.message);
    }
  };

  useEffect(() => {
    fetchProducts();  // Fetch products when the component mounts
  }, [token]);



  const handleAddCart1 = async (event) => {
    event.preventDefault();

    // Create a FormData object to handle cart data
    const cartData = new FormData();
    cartData.append('customer_name', formData.customer_name);
    cartData.append('customer_address', formData.customer_address);
    cartData.append('product_name', formData.product_name);
    cartData.append('delivery_date', formData.delivery_date);
    cartData.append('quantity', formData.quantity);
    cartData.append('units', formData.units);
    cartData.append('sale_price', formData.sale_price);
    cartData.append('discount', formData.discount);
    cartData.append('total_price', formData.total_price);

    try {
      const response = await axios.post(
        'https://ramakumaritrades.co.in/web/cart/create-cart',
        cartData, // Send the form data
        {
          headers: {
            'Content-Type': 'multipart/form-data', // Ensure correct content type for file upload
            Authorization: `Bearer ${token}`, // Include token for authentication
          },
        }
      );

      // Handle success (reset form and state)
      console.log('Cart item added successfully:', response.data);

      if (response.data.success) {
        // Update cart state with the new item
        setCartItems((prevCartItems) => [...prevCartItems, response.data.data]);
        // setShowAddModal(false); // Close modal after success
        setFormData({
          customer_name: "",
          customer_address: "",
          product_name: "",
          delivery_date: "",
          quantity: "",
          units: "",
          sale_price: "",
          discount: "",
          total_price: "",
        });
      }
    } catch (error) {
      console.error('Error adding cart item:', error);
    }
  };

  // delete all cart items

  const deleteCartItem = async () => {
    const customerName = cartItems.length > 0 ? cartItems[0].customer_name : 'Unknown Customer';
    console.log("customerName==>", customerName)
    // setIsLoading(true);
    try {
      const response = await fetch('https://ramakumaritrades.co.in/web/cart/delete-cart-by-customer-name', {
        method: 'DELETE',
        body: JSON.stringify({
          customer_name: customerName,
        }),
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
      });

      const data = await response.json();
      if (data.success) {
        // handleRefund();
        // Handle success if needed
      }
    } catch (error) {
      console.error('Error removing from cart:', error);
    } finally {
    }
  };

  // add to orders

  const addorder = async () => {
    // Map through cart items to create orderItems
    if (cartItems.length === 0) {
      alert("The cart is empty. Please add items before placing an order.");
      return;
    }
    const orderItems = cartItems.map((item) => ({
      product_name: item.product_name,
      sale_price: item.sale_price,
      units: item.units,
      quantity: item.quantity,
      discount: item.discount,
      total_price: item.total_price,
      description: description
    }));

    const customerName = cartItems.length > 0 ? cartItems[0].customer_name : 'Unknown Customer';
    const customerAddress = cartItems.length > 0 ? cartItems[0].customer_address : 'Unknown customeraddress';

    // Calculate total price by summing up the total_price of all cart items
    const totalPrice = cartItems.reduce((sum, item) => sum + item.total_price, 0);

    try {
      const response = await fetch('https://ramakumaritrades.co.in/web/order/create-orders', {
        method: 'POST',
        body: JSON.stringify({
          employee_name: "Admin",
          customer_name: customerName,
          customer_address: customerAddress,
          total_price: totalPrice, // Pass totalAmount as total_price
          product_details: JSON.stringify(orderItems),
          mode_of_payment: "offline"
        }),
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}` // Authorization header for authentication

        },
      });

      const data = await response.json();
      console.log("orderdata==>", data);
      if (data.success) {
        deleteCartItem();
        fetchCartItems();
        setorders(data);
        // Optionally save data in AsyncStorage here
        alert("Your order has been placed successfully.")
      }
    } catch (error) {
      console.error('Error adding to cart:', error);
    }
  };


  const fetchCustomers = async () => {
    try {
      const response = await axios.get(
        "https://ramakumaritrades.co.in/web/customer/get-all-customer",
        {
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      );

      if (response.status !== 200) {
        throw new Error(
          `Failed to fetch customers. Server returned status: ${response.status}`
        );
      }

      console.log("Fetched customers:", response.data);
      const data = response.data;
      setCustomers(data.data || []);
      localStorage.setItem(
        "customer_id",
        JSON.stringify(data.data.map((customer) => customer.customer_id))
      );
    } catch (error) {
      console.error("Error fetching customers:", error.message);
    }
  };

  useEffect(() => {
    fetchCustomers();
  }, [token]);

  // Fetch cart items from the API

  const fetchCartItems = async () => {
    try {
      const response = await axios.get('https://ramakumaritrades.co.in/web/cart/get-all-cart', {
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`
        },
      });

      if (response.status !== 200) {
        throw new Error('Failed to fetch cart items. Status: ' + response.status);
      }

      console.log("Cart data:", response.data);
      const data = response.data;
      setCartItems(data.data || []); // Assuming the API response structure has a "data" property
      localStorage.setItem('cart_item_ids', JSON.stringify(data.data.map(cart => cart.id)));
    } catch (error) {
      console.error('Error fetching cart items:', error);
    }
  };

  useEffect(() => {
    fetchCartItems();
  }, [token]);

  // click edit button

  const handleEditButtonClick = (item) => {
    setFormData({
      customer_name: item.customer_name,
      product_name: item.product_name,
      delivery_date: item.delivery_date,
      quantity: item.quantity,
      units: item.units,
      SalePrice: item.SalePrice,
      discount: item.discount,
      total_price: item.total_price,
    });
    setSelectedCart(item); // Store the selected cart item
    toggleEditModal(true); // Open the modal
  };

  // edit cart

  const handleEditCart = async (e) => {
    e.preventDefault();

    try {
      const formDataToSend = new FormData();

      // Append form data fields for cart
      if (formData.customer_name) formDataToSend.append('customer_name', formData.customer_name);
      if (formData.product_name) formDataToSend.append('product_name', formData.product_name);
      if (formData.delivery_date) formDataToSend.append('delivery_date', formData.delivery_date);
      if (formData.quantity) formDataToSend.append('quantity', formData.quantity);
      if (formData.unit) formDataToSend.append('unit', formData.unit);
      if (formData.SalePrice) formDataToSend.append('SalePrice', formData.SalePrice);
      if (formData.discount) formDataToSend.append('discount', formData.discount);
      if (formData.total_price) formDataToSend.append('total_price', formData.total_price);

      // Always include the cart_id in the update to specify which cart item to update
      formDataToSend.append('cart_id', selectedCart.cart_id);

      // Send PUT request to update the cart item
      const response = await fetch(`https://ramakumaritrades.co.in/web/cart/update-cart`, {
        method: 'PUT',
        body: formDataToSend,
        headers: {
          'Authorization': `Bearer ${token}`,
        },
      });

      if (!response.ok) {
        const errorData = await response.json();
        throw new Error(errorData.message || 'Failed to update cart item.');
      }

      const data = await response.json();

      if (data.success) {
        setCartItems(cartItems.map(cart =>
          cart.cart_id === selectedCart.cart_id
            ? {
              ...cart,
              customer_name: formData.customer_name || cart.customer_name,
              product_name: formData.product_name || cart.product_name,
              delivery_date: formData.delivery_date || cart.delivery_date,
              quantity: formData.quantity || cart.quantity,
              unit: formData.unit || cart.unit,
              SalePrice: formData.SalePrice || cart.SalePrice,
              discount: formData.discount || cart.discount,
              total_price: formData.total_price || cart.total_price,
            }
            : cart
        ));

        toggleEditModal(false); // Close the modal after updating
        fetchCartItems(); // Optionally refetch cart items to get the latest data
      } else {
        console.error('Failed to update cart item:', data.message);
      }
    } catch (error) {
      console.error('Error updating cart item:', error.message);
    }
  };

  // delete cart
  const count = cartItems.length;

  const handleDeleteCart = async (cart_id) => {
    console.log(`Deleting cart item with ID: ${cart_id}`);

    if (!cart_id) {
      console.error('Invalid cart_id:', cart_id);
      return;
    }

    if (window.confirm('Are you sure you want to delete this cart item?')) {
      try {
        const response = await fetch("https://ramakumaritrades.co.in/web/cart/delete-cart-by-id", {
          method: 'DELETE',
          body: JSON.stringify({
            cart_id: cart_id,
          }),
          headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}` // Authorization header for authentication
          },
        });

        // Check for successful response
        if (response.ok) {
          const data = await response.json();
          if (data.success) {
            // Update the state immediately
            setCartItems(prevCartItems => {
              const updatedCartItems = prevCartItems.filter(cart => cart.id !== cart_id);
              console.log('Updated cart items:', updatedCartItems);
              fetchCartItems();
              return updatedCartItems;
            });
          } else {
            console.error('Failed to delete cart item:', data.message);
          }
        } else if (response.status === 404) {
          console.error('Cart item not found. Please check the cart_id or the backend route.');
        } else {
          console.error('Failed to delete cart item. Status:', response.status);
        }
      } catch (error) {
        console.error('Error deleting cart item:', error);
      }
    }
  };

  // get all brands

  const fetchBrands = async () => {
    try {
      const response = await axios.get('https://ramakumaritrades.co.in/web/brand/get-all-brand', {
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`
        },
      });

      if (response.status !== 200) {
        throw new Error('Failed to fetch brands. Status: ' + response.status);
      }

      console.log("data", response.data);
      const data = response.data;
      setBrands(data.data || []);
      localStorage.setItem('brand_id', JSON.stringify(data.data.map(brand => brand.brand_id)));
    } catch (error) {
      console.error('Error fetching brands:', error);
    }
  };

  useEffect(() => {
    fetchBrands();
  }, [token]);




  const handleCustomerChange = (e) => {
    const selectedCustomer = customers.find(customer => customer.customer_name === e.target.value);
    setFormData((prevState) => ({
      ...prevState,
      customer_name: e.target.value,
      customer_address: selectedCustomer ? selectedCustomer.address : "",
    }));
  };

  const handleProductClick = (product) => {
    setFormData((prevState) => ({
      ...prevState,
      product_name: product.product_name,
      units: product.units,
      purchase_price: product.purchase_price,
      sale_price: product.sale_price,
      customer_name: prevState.customer_name, // Retain customer name
      customer_address: prevState.customer_address, // Retain customer address
    }));
    setShowProductDropdown(false);
  };


  const handleAddCart = async (event) => {
    event.preventDefault();

    console.log('Form Data Submitted:', formData);

    const cartData = {
      customer_name: formData.customer_name,
      customer_address: formData.customer_address,
      product_name: formData.product_name,
      delivery_date: formData.delivery_date,
      quantity: formData.quantity,
      units: formData.units,
      sale_price: formData.sale_price,
      discount: formData.discount,
      total_price: formData.total_price,
      brand_name: formData.brand_name,
    };

    try {
      const response = await axios.post(
        'https://ramakumaritrades.co.in/web/cart/create-cart',
        cartData,
        {
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token}`,
          },
        }
      );

      console.log('Cart item added successfully:', response.data);
      if (response.data.success) {
        setCartItems((prevCartItems) => [...prevCartItems, response.data.data]);
        setShowAddModal(false);
        alert("Item successfully added to the cart.")
        resetForm();
      }
    } catch (error) {
      console.error('Error adding cart item:', error.response?.data || error.message);
    }
  };



  return (
    <div className=" h-[calc(100vh-6rem)]   overflow-hidden  flex flex-col">
      {/* Header Section */}
      <div className="flex justify-between mb-4">
      <h6 className="text-2xl font-semibold">Cart Items: <span>{count}</span></h6>
        <button
          onClick={toggleAddModal}
          className="bg-blue-600 text-white px-4 py-2 rounded"
        >
          New Order
        </button>
      </div>

      <div className="h-[calc(100vh-4rem)] flex flex-col">

        {/* Scrollable Cart Items */}
        {cartItems.length === 0 ? (
          // Placeholder content when cartItems is empty
          <div className="flex flex-col items-center justify-center py-10">
            <p className="mt-4 text-gray-600 text-lg font-medium">
              Your cart is empty. Add some items to get started!
            </p>
          </div>
        ) : (
          // Render cart items
          <div className="flex-1 overflow-y-auto mb-4 px-4">
            <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-4 gap-4">
              {cartItems.map((item) => (
                <div
                  key={item.id}
                  onClick={() => handleCartSelection(item)}
                  className="border rounded-lg p-4 shadow-md bg-white flex flex-col justify-between"
                >
                  {/* Product Details */}
                  <div>
                    <h2 className="text-lg font-semibold text-gray-800">
                      {item.product_name}
                    </h2>
                    <p className="text-gray-600">Price: ${item.sale_price}</p>
                    <p className="text-gray-600">Quantity: {item.quantity}</p>
                    <p className="text-gray-600">Discount: {item.discount}%</p>
                    <p className="text-sm font-medium text-gray-600">
                      Total Price: {item.total_price}
                    </p>
                  </div>

                  {/* Action Buttons */}
                  <div className="mt-4 flex justify-between">
                    <button
                      className="text-blue-500 hover:text-blue-700"
                      onClick={() => handleEditButtonClick(item)}
                    >
                      <FaEdit size={18} />
                    </button>
                    <button
                      className="text-red-500 hover:text-red-700"
                      onClick={() => handleDeleteCart(item.cart_id)}
                    >
                      <FaTrashAlt size={18} />
                    </button>
                  </div>
                </div>
              ))}
            </div>
          </div>
        )}


        {/* Fixed Footer Section */}
        <div className="bg-white  p-4 sticky h-24 bottom-0">
          <div className="flex items-center gap-4">
            <form className="flex-1">
              <label htmlFor="description" className="block text-sm font-medium mb-1">
                Description:
              </label>
              <textarea
                id="description"
                name="description"
                value={description}
                onChange={(e) => setDescription(e.target.value)}
                className="border border-black rounded px-2 py-1 w-full h-10"
                placeholder="Enter order description here"
              />
            </form>
            <button
              onClick={addorder}
              className="bg-blue-600 text-white px-4 py-2 h-10 rounded"
            >
              Confirm Order
            </button>
          </div>
        </div>

      </div>






      {/* Add Product Modal */}
      {showAddModal && (
        <div className="fixed inset-0 bg-gray-800 bg-opacity-50 flex justify-center items-center">
          <div className="bg-white p-4 rounded w-full max-w-3xl">
            <div className=' flex justify-between'>
              <h3 className="text-xl font-bold mb-4">Add Brand Item</h3>
              <button
                onClick={toggleAllProductsModal}
                className="bg-blue-600 text-white px-3 py-1 rounded"
              >
                All Products
              </button>
            </div>
            <form
              onSubmit={(e) => {
                handleAddCart(e);
                resetForm(); // Reset form on submission
              }}
            >
              <div className="grid grid-cols-2 gap-2">

                {/* Customer Name */}
                <div>
                  <label className="block text-sm font-medium mb-1">Customer Name:</label>
                  <select
                    name="customer_name"
                    value={formData.customer_name || ""}
                    onChange={handleCustomerChange}
                    required
                    className="border border-gray-300 rounded px-3 py-2 w-full"
                  >
                    <option value="">Select a customer</option>
                    {customers.map((customer) => (
                      <option key={customer.customer_id} value={customer.customer_name}>
                        {customer.customer_name}
                      </option>
                    ))}
                  </select>
                </div>
                <div>
                  <label className="block text-sm font-medium mb-1">Customer Address:</label>
                  <input
                    type="text"
                    name="customer_address"
                    value={formData.customer_address || ""}
                    onChange={(e) => setFormData({ ...formData, customer_address: e.target.value })}
                    className="border border-gray-300 rounded px-3 py-2 w-full"
                  />
                </div>

                {/* Brand Dropdown */}
                <div className="relative">
                  <label className="block text-sm font-medium mb-1">Brand Name:</label>
                  <input
                    type="text"
                    value={selectedBrand}
                    onFocus={() => setShowBrandDropdown(true)}
                    onChange={(e) => setSelectedBrand(e.target.value)}
                    placeholder="Select a brand"
                    className="border border-gray-300 rounded px-3 py-2 w-full"
                  />
                  {showBrandDropdown && (
                    <ul className="absolute z-10 bg-white border border-gray-300 rounded w-full max-h-40 overflow-y-auto">
                      {brands.map((brand) => (
                        <li
                          key={brand.brand_id}
                          onClick={() => {
                            handleBrandSelection(brand); // Call updated function
                            setShowBrandDropdown(false); // Close the dropdown
                          }}
                          className="px-3 py-2 hover:bg-gray-200 cursor-pointer"
                        >
                          {brand.brand_name}
                        </li>
                      ))}
                    </ul>
                  )}
                </div>

                {/* Product Dropdown */}
                <div className="relative mt-4">
                  <label className="block text-sm font-medium mb-1">Product Name:</label>
                  <input
                    type="text"
                    value={formData.product_name}
                    onFocus={() => {
                      setShowProductDropdown(true);
                      if (!selectedBrand) {
                        setFilteredProducts(allProducts); // Show all products if no brand is selected
                      }
                    }}
                    onChange={(e) => {
                      setFormData({ ...formData, product_name: e.target.value });
                      setFilteredProducts(
                        allProducts.filter((product) =>
                          product.product_name
                            .toLowerCase()
                            .includes(e.target.value.toLowerCase())
                        )
                      );
                    }}
                    placeholder="Select a product"
                    className="border border-gray-300 rounded px-3 py-2 w-full"
                  />
                  {showProductDropdown && filteredProducts.length > 0 && (
                    <ul className="absolute z-10 bg-white border border-gray-300 rounded w-full max-h-40 overflow-y-auto">
                      {filteredProducts.map((product) => (
                        <li
                          key={product.product_id}
                          onClick={() => {
                            handleProductClick(product);
                            setShowProductDropdown(false); // Close dropdown after selection
                          }}
                          className="px-3 py-2 hover:bg-gray-200 cursor-pointer"
                        >
                          {product.product_name}
                        </li>
                      ))}
                    </ul>
                  )}
                </div>

                {/* Auto-filled Fields */}
                <div className="mt-4">
                  <label className="block text-sm font-medium mb-1">Units:</label>
                  <input
                    type="text"
                    value={formData.units || ""}
                    readOnly
                    className="border border-gray-300 rounded px-3 py-2 w-full bg-gray-100"
                  />
                </div>

                <div className="mt-4">
                  <label className="block text-sm font-medium mb-1">Purchase Price:</label>
                  <input
                    type="text"
                    value={formData.purchase_price || ""}
                    readOnly
                    className="border border-gray-300 rounded px-3 py-2 w-full bg-gray-100"
                  />
                </div>

                <div className="mt-4">
                  <label className="block text-sm font-medium mb-1">Sale Price:</label>
                  <input
                    type="text"
                    name="sale_price"
                    value={formData.sale_price || ""}
                    onChange={handleInputChange}
                    className="border border-gray-300 rounded px-3 py-2 w-full"
                  />
                </div>

                {/* Quantity */}
                <div>
                  <label className="block text-sm font-medium mb-1">Quantity:</label>
                  <input
                    type="number"
                    name="quantity"
                    value={formData.quantity || ""}
                    onChange={handleInputChange}
                    required
                    className="border border-gray-300 rounded px-3 py-2 w-full"
                  />
                </div>

                {/* Discount */}
                <div>
                  <label className="block text-sm font-medium mb-1">Discount (%):</label>
                  <input
                    type="number"
                    name="discount"
                    value={formData.discount || ""}
                    onChange={handleInputChange}
                    className="border border-gray-300 rounded px-3 py-2 w-full"
                  />
                </div>

                {/* Amount */}
                <div>
                  <label className="block text-sm font-medium mb-1">Total Price:</label>
                  <input
                    type="number"
                    name="total_price"
                    value={formData.total_price || ""}
                    readOnly
                    className="border border-gray-300 rounded px-3 py-2 w-full bg-gray-100"
                  />
                </div>
              </div>

              {/* Buttons */}
              <div className="flex justify-between items-center mt-6">
                <button
                  type="button"
                  onClick={handleAddMore && handleAddCart1
                  }
                  className="text-blue-600 text-sm font-medium flex items-center"
                >
                  <span className="mr-1 text-xl">+</span> Add More
                </button>
                <div>
                  <button type="submit" className="bg-blue-600 text-white px-4 py-2 rounded">
                    Done
                  </button>
                  <button
                    type="button"
                    onClick={() => {
                      toggleAddModal();
                      resetForm(); // Reset form on modal close
                    }}
                    className="bg-gray-400 text-white px-4 py-2 rounded ml-4"
                  >
                    Cancel
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>
      )}


      {/* All Products Modal */}
      {showAllProductsModal && (
        <div className="fixed inset-0 bg-gray-800 bg-opacity-50 flex justify-center items-center">
          <div className="bg-white p-4 rounded w-full max-w-3xl">
            <div className="flex justify-between">
              <h3 className="text-xl font-bold mb-4">All Products</h3>
              <button
                onClick={handleDoneInAllProducts}
                className="bg-blue-600 text-white px-3 py-1 rounded"
              >
                Brand Product
              </button>
            </div>
            <form
              onSubmit={(e) => {
                handleAddCart(e);
                resetForm(); // Reset form on submission
              }}
            >
              <div className="grid grid-cols-2 gap-2">
                {/* Customer Name */}
                <div>
                  <label className="block text-sm font-medium mb-1">Customer Name:</label>
                  <select
                    name="customer_name"
                    value={formData.customer_name || ""}
                    onChange={handleCustomerChange}
                    required
                    className="border border-gray-300 rounded px-3 py-2 w-full"
                  >
                    <option value="">Select a customer</option>
                    {customers.map((customer) => (
                      <option key={customer.customer_id} value={customer.customer_name}>
                        {customer.customer_name}
                      </option>
                    ))}
                  </select>
                </div>

                <div>
                  <label className="block text-sm font-medium mb-1">Customer Address:</label>
                  <input
                    type="text"
                    name="customer_address"
                    value={formData.customer_address || ""}
                    onChange={(e) =>
                      setFormData({ ...formData, customer_address: e.target.value })
                    }
                    className="border border-gray-300 rounded px-3 py-2 w-full"
                  />
                </div>

                {/* Product Name Input */}
                <div className="mt-4">
                  <label className="block text-sm font-medium mb-1">Product Name:</label>
                  <input
                    type="text"
                    value={formData.product_name || ''}
                    onFocus={() => {
                      setShowProductDropdown(true); // Show the dropdown when input is focused
                      setFilteredProducts(products); // Show all products initially
                    }}
                    onChange={(e) => {
                      const inputValue = e.target.value;
                      setFormData({ ...formData, product_name: inputValue });
                      setFilteredProducts(
                        products.filter((product) =>
                          product.product_name.toLowerCase().includes(inputValue.toLowerCase())
                        )
                      ); // Filter products based on input value
                    }}
                    className="border border-gray-300 rounded px-3 py-2 w-full bg-gray-100"
                    placeholder="Search for a product"
                  />

                  {/* Product Dropdown */}
                  {showProductDropdown && filteredProducts.length > 0 && (
                    <ul className="absolute z-10 bg-white border border-gray-300 rounded w-auto  overflow-y-auto">
                      {filteredProducts.map((product) => (
                        <li
                          key={product.product_id}
                          onClick={() => handleProductClick(product)} // Handle product selection
                          className="px-3 py-2 hover:bg-gray-200 cursor-pointer"
                        >
                          {product.product_name}
                        </li>
                      ))}
                    </ul>
                  )}
                </div>
                {/* Auto-filled Fields */}
                <div className="mt-4">
                  <label className="block text-sm font-medium mb-1">Units:</label>
                  <input
                    type="text"
                    value={formData.units || ""}
                    readOnly
                    className="border border-gray-300 rounded px-3 py-2 w-full bg-gray-100"
                  />
                </div>

                <div className="mt-4">
                  <label className="block text-sm font-medium mb-1">Purchase Price:</label>
                  <input
                    type="text"
                    value={formData.purchase_price || ""}
                    readOnly
                    className="border border-gray-300 rounded px-3 py-2 w-full bg-gray-100"
                  />
                </div>

                <div className="mt-4">
                  <label className="block text-sm font-medium mb-1">Sale Price:</label>
                  <input
                    type="text"
                    name="sale_price"
                    value={formData.sale_price || ""}
                    onChange={handleInputChange}
                    className="border border-gray-300 rounded px-3 py-2 w-full"
                  />
                </div>

                {/* Quantity */}
                <div>
                  <label className="block text-sm font-medium mb-1">Quantity:</label>
                  <input
                    type="number"
                    name="quantity"
                    value={formData.quantity || ""}
                    onChange={handleInputChange}
                    required
                    className="border border-gray-300 rounded px-3 py-2 w-full"
                  />
                </div>

                {/* Discount */}
                <div>
                  <label className="block text-sm font-medium mb-1">Discount (%):</label>
                  <input
                    type="number"
                    name="discount"
                    value={formData.discount || ""}
                    onChange={handleInputChange}
                    className="border border-gray-300 rounded px-3 py-2 w-full"
                  />
                </div>

                {/* Amount */}
                <div>
                  <label className="block text-sm font-medium mb-1">Total Price:</label>
                  <input
                    type="number"
                    name="total_price"
                    value={formData.total_price || ""}
                    readOnly
                    className="border border-gray-300 rounded px-3 py-2 w-full bg-gray-100"
                  />
                </div>
              </div>

              {/* Buttons */}
              <div className="flex justify-between items-center mt-6">
                {/* <button
                  type="button"
                  onClick={handleAddMore}
                  className="text-blue-600 text-sm font-medium flex items-center"
                >
                  <span className="mr-1 text-xl">+</span> Add More
                </button> */}
                <div>
                  <button type="submit" className="bg-blue-600 text-white px-4 py-2 rounded">
                    ADD
                  </button>
                  <button
                    type="button"
                    onClick={() => {
                      setShowAllProductsModal(false);
                      resetForm(); // Reset form on modal close
                    }}
                    className="bg-gray-400 text-white px-4 py-2 rounded ml-4"
                  >
                    Close
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>
      )}








      {showEditModal && (
        <div className="fixed inset-0 bg-gray-800 bg-opacity-50 flex justify-center items-center">
          <div className="bg-white p-6 rounded w-full max-w-3xl">
            <h2 className="text-xl font-bold mb-4">Edit Cart Item</h2>
            <form
              onSubmit={(e) => handleEditCart(e, formData)} // Handle edit cart on submit
            >
              <div className="grid grid-cols-2 gap-4">

                {/* Customer Name */}
                <div>
                  <label className="block text-sm font-medium mb-1">Customer Name:</label>
                  <select
                    name="customer_name"
                    value={formData.customer_name}
                    onChange={handleInputChange}
                    required
                    className="border border-gray-300 rounded px-3 py-2 w-full"
                  >
                    <option value="">Select a customer</option>
                    {customers.map((customer) => (
                      <option key={customer.customer_id} value={customer.customer_name}>
                        {customer.customer_name}
                      </option>
                    ))}
                  </select>
                </div>

                {/* Product Name */}
                <div className="col-span-2 relative">
                  <label className="block text-sm font-medium mb-1">Product Name:</label>
                  <input
                    type="text"
                    name="product_name"
                    value={formData.product_name}
                    onChange={handleItemSearch}
                    onFocus={() => setShowResults(true)} // Show results when input is focused
                    required
                    className="border border-gray-300 rounded px-3 py-2 w-full"
                    placeholder="Search item..."
                  />
                  {showResults && searchResults.length > 0 && (
                    <ul className="absolute z-10 bg-white border border-gray-300 rounded w-full max-h-40 overflow-y-auto">
                      {searchResults.map((product) => (
                        <li
                          key={product.product_id}
                          onClick={() => {
                            setFormData({
                              ...formData,
                              product_name: product.product_name,
                            });
                            setSearchResults([]); // Clear search results
                            setShowResults(false); // Hide results
                          }}
                          className="px-3 py-2 hover:bg-gray-200 cursor-pointer"
                        >
                          {product.product_name}
                        </li>
                      ))}
                    </ul>
                  )}
                </div>

                {/* Quantity */}
                <div>
                  <label className="block text-sm font-medium mb-1">Quantity:</label>
                  <input
                    type="number"
                    name="quantity"
                    value={formData.quantity}
                    onChange={handleInputChange}
                    required
                    className="border border-gray-300 rounded px-3 py-2 w-full"
                  />
                </div>

                {/* Unit */}
                <div>
                  <label className="block text-sm font-medium mb-1">Units:</label>
                  <select
                    name="units"
                    value={formData.units}
                    onChange={handleInputChange}
                    required
                    className="border border-gray-300 rounded px-3 py-2 w-full"
                  >
                    <option value="">Select Units</option>
                    <option value="Kg">Kg</option>
                    <option value="Box">Box</option>
                    <option value="Bag">Bag</option>
                    <option value="Dozen">Dozen</option>
                    <option value="Pieces">Pieces</option>
                  </select>
                </div>

                {/* Sale Price */}
                <div>
                  <label className="block text-sm font-medium mb-1">Sale Price:</label>
                  <input
                    type="number"
                    name="SalePrice"
                    value={formData.SalePrice}
                    onChange={handleInputChange}
                    required
                    className="border border-gray-300 rounded px-3 py-2 w-full"
                  />
                </div>

                {/* Discount */}
                <div>
                  <label className="block text-sm font-medium mb-1">Discount (%):</label>
                  <input
                    type="number"
                    name="discount"
                    value={formData.discount}
                    onChange={handleInputChange}
                    className="border border-gray-300 rounded px-3 py-2 w-full"
                  />
                </div>

                {/* Total Price */}
                <div>
                  <label className="block text-sm font-medium mb-1">Total Price:</label>
                  <input
                    type="number"
                    name="total_price"
                    value={formData.total_price}
                    readOnly
                    className="border border-gray-300 rounded px-3 py-2 w-full bg-gray-100"
                  />
                </div>
              </div>

              {/* Buttons */}
              <div className="flex justify-between items-center mt-6">
                <div>
                  <button
                    type="submit"
                    className="bg-blue-600 text-white px-4 py-2 rounded"
                  >
                    Done
                  </button>
                  <button
                    type="button"
                    onClick={() => toggleEditModal(false)} // Close modal on cancel
                    className="bg-gray-400 text-white px-4 py-2 rounded ml-4"
                  >
                    Cancel
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>
      )}









    </div>

  );
}

export default Cart;
