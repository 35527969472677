import React, { useEffect, useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { FaEdit, FaTrash } from 'react-icons/fa';
import axios from 'axios';
import jsPDF from 'jspdf';
import html2canvas from 'html2canvas';

const Invoice = () => {
  const { order_id } = useParams();
  const [orders, setOrders] = useState([]);
  const navigate = useNavigate();
  const [formData, setFormData] = useState({
    sale_price: '',
    quantity: '',
    discount: '',
    description: '',

  });
  const [showEditModal, setShowEditModal] = useState(false);
  const [products, setProducts] = useState([]);
  const [selectedProduct, setSelectedProduct] = useState(null);

  const token = localStorage.getItem("accessToken");

  useEffect(() => {
    const fetchOrders = async () => {
      if (!token) {
        console.error('Authorization token is missing!');
        return;
      }

      try {
        const response = await axios.get(
          'https://ramakumaritrades.co.in/web/order/get-all-order',
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
        const data = response.data;

        setOrders(response.data.data || []);
        console.log("Fetched orders:", response.data.data);
        localStorage.setItem(
          "order_id",
          JSON.stringify(data.data.map((orders) => orders.order_id))
        );
      } catch (error) {
        console.error('Error fetching orders:', error.message);
      }
    };

    fetchOrders();
  }, []);

  const selectedOrder = orders.find((order) => order.order_id === order_id);

  if (!selectedOrder) {
    return <div>Loading...</div>;
  }

  const handlePrint = async () => {
    const content = document.getElementById('invoice-content');
    const canvas = await html2canvas(content, {
      scale: 2, // Improves the resolution of the canvas
      useCORS: true, // Handles cross-origin issues if any assets are external
    });

    const imgData = canvas.toDataURL('image/png');
    const pdf = new jsPDF('p', 'mm', 'a4');
    const pdfWidth = pdf.internal.pageSize.getWidth();
    const pdfHeight = (canvas.height * pdfWidth) / canvas.width;

    // Add margins to the PDF
    const margin = 10;
    pdf.addImage(imgData, 'PNG', margin, margin, pdfWidth - 2 * margin, pdfHeight - 2 * margin);
    pdf.save('invoice.pdf');
  };

  const toggleEditModal = (product, index) => {
    setSelectedProduct({ ...product, index }); // Attach index to the selected product
    setFormData({
      sale_price: product.sale_price || '',
      quantity: product.quantity || '',
      discount: product.discount || '',
      description: product.description || '',

    });
    setShowEditModal(!showEditModal);
  };


  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value
    }));
  };



  const handleEditProduct = async (e) => {
    e.preventDefault();
  
    try {
      const productDetails = Array.isArray(selectedOrder.product_details)
        ? selectedOrder.product_details
        : JSON.parse(selectedOrder.product_details);
  
      const { index } = selectedProduct;
  
      productDetails[index] = {
        ...productDetails[index],
        quantity: Number(formData.quantity) || productDetails[index].quantity,
        sale_price: Number(formData.sale_price) || productDetails[index].sale_price,
        discount: Number(formData.discount) || productDetails[index].discount,
        description: formData.description || productDetails[index].description,
        total_price:
          (Number(formData.quantity || productDetails[index].quantity) *
            Number(formData.sale_price || productDetails[index].sale_price) *
            (1 - (Number(formData.discount || productDetails[index].discount) / 100))).toFixed(2),
      };
  
      // Update the total amount for the order
      const updatedTotalAmount = productDetails.reduce(
        (acc, product) =>
          acc +
          product.quantity *
          product.sale_price *
          (1 - product.discount / 100),
        0
      );
  console.log("updatedTotalAmount",updatedTotalAmount)
      // Update the order in the backend or state
      const updatedOrder = {
        ...selectedOrder,
        product_details: JSON.stringify(productDetails),
        total_price: updatedTotalAmount.toFixed(2),
      };
  
  
      // Send the updated data to the backend
      const response = await axios.put(
        `https://ramakumaritrades.co.in/web/order/update-orders-name`,
        updatedOrder,
        {
          headers: {
            'Authorization': `Bearer ${token}`,
          },
        }
      );
  
      if (response.data.success) {
        // Update local state
        setOrders((prevOrders) =>
          prevOrders.map((order) =>
            order.order_id === selectedOrder.order_id ? updatedOrder : order
          )
        );
        setShowEditModal(false); // Close modal
      } else {
        console.error('Failed to update product details:', response.data.message);
      }
    } catch (error) {
      console.error('Error updating product:', error.response?.data || error.message);
    }
  };
  

  return (
    <div className="max-w-screen-lg mx-auto my-2 px-4 border rounded-lg shadow-lg overflow-y-auto  h-[calc(100vh-6rem)]   overflow-hidden" >
      {/* Invoice Content */}
      <div id="invoice-content" className="bg-white p-6 rounded-lg  h-[calc(100vh-4rem)] overflow-y-auto">
        {/* Header */}
        <div className="text-black text-center p-2 rounded-t-lg">
          <h1 className="text-3xl font-bold">Quotation</h1>
          <p className="text-lg mt-2">Rama Kumari Trades</p>
        </div>

        <div className="relative">
          <div className="absolute w-full h-[5px] bg-black top-0"></div>
        </div>

        {/* Details Section */}
        <div className="grid grid-cols-2 gap-6 mt-6">
          <div>
            <h3 className="text-lg font-bold mb-2">BILL TO:</h3>
            <p>{selectedOrder.customer_name}</p>
            <p>{selectedOrder.customer_address}</p>
          </div>
          <div className="text-right">
            <p><span className="font-bold">Order ID:</span> {selectedOrder.order_id}</p>
            <p><span className="font-bold">Date:</span> {new Date().toLocaleDateString()}</p>
            <p><span className="font-bold">Status:</span> {selectedOrder.status}</p>
          </div>
        </div>

        {/* Products Table */}
        <div className="mt-6">
          <table className="w-full border-collapse border border-gray-300">
            <thead>
              <tr className="bg-gray-200 text-gray-700">
                <th className="border-b p-2 text-center">S.NO</th>
                <th className="border-b p-2 text-center">ITEM</th>
                <th className="border-b p-2 text-center">UNITS</th>
                <th className="border-b p-2 text-center">QUANTITY</th>
                <th className="border-b p-2 text-center">PRICE</th>
                <th className="border-b p-2 text-center">DISCOUNT</th>
                <th className="border-b p-2 text-center">AMOUNT</th>
                <th className="border-b p-2 text-center">ACTION</th>
              </tr>
            </thead>
            <tbody>
         
              {selectedOrder.product_details && (
                <>
                      {/* Safely parse product details */}
                      {(() => {
                        try {
                          const productDetails = JSON.parse(selectedOrder.product_details);
                          return productDetails.map((product, index) => (
                            <tr key={index} className="text-center">
                              <td className="border-b p-2">{index + 1}</td>
                              <td className="border-b p-2">{product.product_name}</td>
                              <td className="border-b p-2">{product.units}</td>
                              <td className="border-b p-2">{product.quantity}</td>
                              <td className="border-b p-2">₹{product.sale_price}</td>
                              <td className="border-b p-2">{product.discount}%</td>
                              <td className="border-b p-2">
                                ₹
                                {(
                                  product.quantity * product.sale_price * (1 - product.discount / 100)
                                ).toFixed(2)}
                              </td>
                              <td className="border border-gray-300 px-4 py-2 text-center">
                                <button
                                  onClick={() => toggleEditModal(product, index)}
                                  className="text-blue-600 hover:text-blue-800 mx-2"
                                >
                                  <FaEdit size={16} />
                                </button>
                              </td>
                            </tr>
                          ));
                        } catch (error) {
                          console.error("Error parsing product details:", error);
                          return (
                            <tr>
                              <td colSpan="8" className="text-red-600">
                                Error loading product details.
                              </td>
                            </tr>
                          );
                        }
                      })()}
                    

                  {/* Display product descriptions */}
                  <div className="mt-4 bottom-20">
                    <h3 className="text-lg font-semibold mb-2">Description:</h3>
                    {(() => {
                      try {
                        const productDetails = JSON.parse(selectedOrder.product_details);

                        return productDetails.map((product, index) => (
                          <p key={index} className="mb-1">
                            {product.description || "No description available"}
                          </p>
                        ));
                      } catch (error) {
                        console.error("Error parsing product details for descriptions:", error);
                        return <p className="text-red-600">Error loading descriptions.</p>;
                      }
                    })()}
                  </div>
                </>
              )}
            </tbody>
          </table>
        </div>




        {/* Footer */}
        <div className="text-right mt-6">
          <p className="text-2xl font-bold">
            <span>Total:</span> ₹
            {selectedOrder.product_details
              ? (() => {
                const productDetails = Array.isArray(selectedOrder.product_details)
                  ? selectedOrder.product_details
                  : JSON.parse(selectedOrder.product_details); // Ensure it's an array

                if (productDetails.length === 1) {
                  // Single product, calculate and display its amount
                  const product = productDetails[0];
                  return (
                    (product.quantity *
                      product.sale_price *
                      (1 - product.discount / 100)).toFixed(2)
                  );
                } else {
                  // Multiple products, sum amounts
                  const totalAmount = productDetails.reduce(
                    (acc, product) =>
                      acc +
                      product.quantity *
                      product.sale_price *
                      (1 - product.discount / 100),
                    0
                  );
                  return totalAmount.toFixed(2);
                }
              })()
              : '0.00'}
          </p>
        </div>

      </div>

      {/* Buttons */}
      <div className="flex justify-between mt-4 mb-4">
        <button onClick={() => navigate(-1)} className="bg-red-500 text-white px-4 py-2 rounded-md">Back</button>
        <button onClick={handlePrint} className="bg-blue-500 text-white px-4 py-2 rounded-md">Print Quotation</button>
      </div>




      {/* Edit Product Modal */}
      {showEditModal && (
        <div className="fixed inset-0 bg-gray-800 bg-opacity-50 flex justify-center items-center">
          <div className="bg-white p-6 rounded w-full max-w-96 h-full max-h-[60vh] overflow-y-auto">
            <h2 className="text-xl mb-4">Edit Product</h2>
            <form onSubmit={handleEditProduct}>
              <div className="grid grid-cols-2 gap-4">
                <div>
                  <label>Quantity:</label>
                  <input
                    type="text"
                    name="quantity"
                    value={formData.quantity}
                    onChange={handleInputChange}
                    className="w-full p-2 border rounded mt-1"
                  />
                </div>
                <div>
                  <label>Sale Price:</label>
                  <input
                    type="text"
                    name="sale_price"
                    value={formData.sale_price || ''}
                    onChange={handleInputChange}
                    className="w-full p-2 border rounded mt-1"
                  />
                </div>
                <div>
                  <label>Discount (%):</label>
                  <input
                    type="text"
                    name="discount"
                    value={formData.discount || ''}
                    onChange={handleInputChange}
                    className="w-full p-2 border rounded mt-1"
                  />
                </div>
                <div>
                  <label>Final Price:</label>
                  <p className="border p-2 rounded mt-1 bg-gray-100">
                    ₹
                    {formData.discount && formData.discount > 0
                      ? (
                        (formData.quantity * formData.sale_price) *
                        (1 - formData.discount / 100)
                      ).toFixed(2)
                      : (formData.quantity * formData.sale_price).toFixed(2)}
                  </p>
                </div>
              </div>

              <div>
                <label>  Description:</label>
                <textarea
                  type="text"
                  name="description"
                  value={formData.description || ''}
                  onChange={handleInputChange}
                  className="w-full p-2 border rounded mt-1"
                  placeholder="Enter order description here"
                />
              </div>



              <div className="flex justify-end mt-4">
                <button type="submit" className="bg-blue-500 text-white px-4 py-2 rounded-md">
                  Save
                </button>
                <button
                  onClick={() => setShowEditModal(false)}
                  type="button"
                  className="ml-4 bg-gray-500 text-white px-4 py-2 rounded-md"
                >
                  Cancel
                </button>
              </div>
            </form>
          </div>
        </div>
      )}

    </div>
  );
};

export default Invoice;
