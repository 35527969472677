import { useState, useEffect } from 'react';
import { FaEdit, FaTrash } from 'react-icons/fa';
import axios from 'axios';
import * as XLSX from 'xlsx'; // For Excel download
import { FaEye, FaEyeSlash } from 'react-icons/fa'; // Ensure you have icons imported

function EmployeePage() {
  const [showAddModal, setShowAddModal] = useState(false); // Removed duplicate declaration
  const [showEditModal, setShowEditModal] = useState(false);
  const [formData, setFormData] = useState({
    employee_id: '',
    employee_name: '',
    image: null,
    mobile_number: '',
    email: '',
    password: '',
    status: '',
    role: '',
    gender: '',
    Address: '',
    area_name: '',
    area_id: ''
  });
  const [employees, setEmployees] = useState([]);
  const [selectedEmployee, setSelectedEmployee] = useState(null);
  const [searchTerm, setSearchTerm] = useState('');
  const [csvFile, setCsvFile] = useState(null);
  const [showPassword, setShowPassword] = useState(false);
  const [showForgotPasswordModal, setShowForgotPasswordModal] = useState(false);
  const [email, setEmail] = useState('');
  const [passwordVisible, setPasswordVisible] = useState(false);
  const [areas, setAreas] = useState([]);

  const token = localStorage.getItem("accessToken");

  // get all employees

  const fetchEmployees = async () => {
    try {
      const response = await axios.get('https://ramakumaritrades.co.in/web/employee/get-all-employee', {
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`
        },
      });

      if (response.status !== 200) {
        throw new Error('Failed to fetch employees. Status: ' + response.status);
      }

      const data = response.data;
      setEmployees(data.data || []);

      localStorage.setItem('employee_id', JSON.stringify(data.data.map(employee => employee.employee_id)));
    } catch (error) {
      console.error('Error fetching employees:', error);
    }
  };
  useEffect(() => {
    fetchEmployees();
  }, [token]);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value
    }));
  };

  const handleFileChange = (e) => {
    const file = e.target.files[0];
    setFormData((prevData) => ({
      ...prevData,
      image: file
    }));
  };

  const toggleAddModal = () => {
    setShowAddModal(!showAddModal);
    // setFormData(); 
  };

  // const toggleForgotPasswordModal = () => {
  //   setShowForgotPasswordModal(!showForgotPasswordModal);
  // };

  const handleSearchChange = (e) => {
    setSearchTerm(e.target.value);
  };

  const handleCsvChange = (e) => {
    setCsvFile(e.target.files[0]);
  };

  const toggleEditModal = (employee) => {
    setSelectedEmployee(employee);
    setFormData(employee || {
      employee_name: '',
      employee_id: '',
      image: null,
      mobile_number: '',
      email: '',
      password: '',
      status: '',
      role: '',
      gender: '',
      Address: '',
      area_name: '',
      area_id: ''
    });
    setShowEditModal(!showEditModal);
  };


  // add employees......

  const handleAddEmployee = async (event) => {
    event.preventDefault();

    // Create a FormData object to handle both text data and file uploads

    const employeeData = new FormData();
    employeeData.append('employee_name', formData.employee_name);
    employeeData.append('mobile_number', formData.mobile_number);
    employeeData.append('email', formData.email);
    employeeData.append('password', formData.password);
    employeeData.append('role', formData.role);
    employeeData.append('gender', formData.gender);
    employeeData.append('Address', formData.Address);
    employeeData.append('area_name', formData.area_name);
    employeeData.append('area_id', formData.area_id);

    // Append the image file (if provided)
    if (formData.image) {
      employeeData.append('image', formData.image);
    }

    try {
      const response = await axios.post(
        'https://ramakumaritrades.co.in/web/employee/create-employee',
        employeeData, // Send the form data
        {
          headers: {
            'Content-Type': 'multipart/form-data', // Ensure correct content type for file upload
          },
        }
      );

      // Handle success (you can reset the form or close the modal)
      console.log('Employee added successfully:', response.data);
      if (response.data.success) {
        // Update the product list state and close modal
        setEmployees([...employees, response.data.data]);
        toggleAddModal();
      }
      // Reset form or handle success case here
    } catch (error) {
      alert("Email already exists.");

    }
  };


  // csv upload file

  const handleUploadCsv = async () => {
    if (!csvFile) {
      console.error("No CSV file selected");
      return;
    }

    const token = localStorage.getItem('accessToken');
    if (!token) {
      console.error("Authorization token is missing.");
      return;
    }

    try {
      // Prepare FormData with the file
      const formData = new FormData();
      formData.append('csv_file', csvFile); // Appending CSV file with the correct key "csv_file"

      // Send the POST request to bulk upload the products
      const response = await fetch('https://ramakumaritrades.co.in/web/employee/bulk-upload-employee', {
        method: 'POST',
        body: formData,
        headers: {
          'Authorization': `Bearer ${token}`,
          // No need to set Content-Type; it will be set automatically with FormData
        }
      });

      // Check if the upload was successful
      if (!response.ok) {
        throw new Error(`Failed to upload CSV file: ${response.statusText}`);
      }

      const data = await response.json(); // Server response
      console.log("Server response:", data);


      // Assuming fetchProductData() reloads product data in the UI
      alert("CSV file uploaded and employee added successfully.");
      fetchEmployees();
    } catch (error) {
      console.error('Error during CSV upload:', error.message);
      alert('An error occurred during the upload process.');
    }
  };

  // edit employee

  const handleEditEmployee = async (e) => {
    e.preventDefault();
    try {
      const formDataToSend = new FormData();

      // Append form data fields only if they have values
      if (formData.employee_name) formDataToSend.append('emplyee_name', formData.employee_name);
      if (formData.image) formDataToSend.append('image', formData.image);
      if (formData.mobile_number) formDataToSend.append('mobile_number', formData.mobile_number);
      if (formData.email) formDataToSend.append('email', formData.email);
      if (formData.password) formDataToSend.append('password', formData.password);
      if (formData.role) formDataToSend.append('role', formData.role);
      if (formData.gender) formDataToSend.append('gender', formData.gender);
      if (formData.Address) formDataToSend.append('Address', formData.Address);
      if (formData.area_name) formDataToSend.append('area_name', formData.area_name);
      if (formData.area_id) formDataToSend.append('area_id', formData.area_id);

      // Always include the employee_id in the update
      formDataToSend.append('employee_id', selectedEmployee.employee_id);

      const response = await fetch(`https://ramakumaritrades.co.in/web/employee/update-employee`, {
        method: 'PUT',
        body: formDataToSend,
        headers: {
          'Authorization': `Bearer ${token}` // Authorization header for authentication
        },
      });

      if (!response.ok) {
        const errorData = await response.json();
        throw new Error(errorData.message || 'Failed to update employee.');
      }

      const data = await response.json();

      if (data.success) {
        // Update the employees state based on employee_id
        setEmployees(employees.map(employee =>
          employee.employee_id === selectedEmployee.employee_id
            ? {
              ...employee,
              employee_name: formData.employee_name || employee.employee_name,
              mobile_number: formData.mobile_number || employee.mobile_number,
              email: formData.email || employee.email,
              password: formData.password || employee.password,
              role: formData.role || employee.role,
              gender: formData.gender || employee.gender,
              Address: formData.Address || employee.Address,
              area_name: formData.area_name || employee.area_name,
              area_id: formData.area_id || employee.area_id,
              image: formData.image || employee.image
            }
            : employee
        ));
        toggleEditModal(false); // Close the edit modal after successful update
        fetchEmployees();
      } else {
        console.error('Failed to update employee:', data.message);
      }
    } catch (error) {
      console.error('Error updating employee:', error.message);
    }
  };

  // delete

  const handleDeleteEmployee = async (employee_id) => {
    console.log(`Deleting employee with ID: ${employee_id}`);

    if (!employee_id) {
      console.error('Invalid employee_id:', employee_id);
      return;
    }

    if (window.confirm('Are you sure you want to delete this employee?')) {
      try {
        // Save the employee_id to localStorage
        localStorage.setItem('employee_id', employee_id);

        const response = await fetch("https://ramakumaritrades.co.in/web/employee/delete-employee-by-id", {
          method: 'DELETE',
          body: JSON.stringify({
            employee_id: employee_id,
          }),
          headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}` // Ensure the token is valid
          },
        });

        // Check for a successful response
        if (response.ok) {
          const data = await response.json();
          if (data.success) {
            // Update the state to reflect the deleted employee
            console.log("eeeeeeppp=>", employees)
            setEmployees(prevEmployees => prevEmployees.filter(employee => employee.employee_id !== employee_id));
            console.log('Employee deleted successfully');
          } else {
            console.error('Failed to delete employee:', data.message);
          }
        } else if (response.status === 404) {
          console.error('Employee not found. Please check the employee_id or the backend route.');
        } else {
          console.error('Failed to delete employee. Status:', response.status);
        }
      } catch (error) {
        console.error('Error deleting employee:', error);
      }
    }
  };

  // download excel

  const handleDownloadExcel = () => {
    const dataToExport = employees.map(employee => ({
      // 'Employee ID': employee.id,
      'Employee Name': employee.employee_name,
      'Phone Number': employee.mobile_number,
      'Email': employee.email,
      'Password': employee.password,
      'Role': employee.role,
      'Gender': employee.gender,
      'Address': employee.Address,
      'Area Name': employee.area_name,
      'Area ID': employee.area_id,
      // 'Employee Image': employee.image
    }));

    const worksheet = XLSX.utils.json_to_sheet(dataToExport);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, 'Employees');
    XLSX.writeFile(workbook, 'employees.xlsx');
  };

  const filteredEmployees = employees.filter((employee) => {
    const employeeName = employee.employee_name || ''; // Ensure name is not undefined
    return employeeName.toLowerCase().includes(searchTerm.toLowerCase());
  });

  // const handleSubmitForgotPassword = async (e) => {
  //   e.preventDefault();

  //   // Validate matching passwords
  //   if (newPassword !== confirmPassword) {
  //     alert("Passwords do not match.");
  //     return;
  //   }

  //   // API call to reset password
  //   try {
  //     const response = await axios.post('https://ramakumaritrades.co.in/web/employee/forgot-password', {
  //       email: email,
  //       newpassword: newPassword,
  //       confirmpassword: confirmPassword,
  //     }, {
  //       headers: { Authorization: `Bearer ${token}` },
  //     });

  //     if (response.data.success) {
  //       alert("Password reset successful!");
  //       setShowForgotPasswordModal(false); // Close modal
  //     } else {
  //       setEmailError("Email not matched.");
  //     }
  //   } catch (error) {
  //     console.error(error);
  //     setEmailError("Email not matched.");
  //   }
  // };

  // get all areas

  const fetchAreas = async () => {
    try {
      const response = await axios.get('https://ramakumaritrades.co.in/web/area/get-all-area', {
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`
        },
      });

      if (response.status !== 200) {
        throw new Error('Failed to fetch areas. Status: ' + response.status);
      }

      console.log("data", response.data);
      const data = response.data;
      setAreas(data.data || []); // Update state with area data
    } catch (error) {
      console.error('Error fetching areas:', error);
    }
  };

  useEffect(() => {
    fetchAreas();
  }, [token]);

  // Handle area selection change
  const handleAreaChange = (event) => {
    const selectedAreaId = event.target.value;
    const selectedArea = areas.find(area => area.area_id === selectedAreaId);

    setFormData({
      ...formData,
      area_name: selectedArea ? selectedArea.area_name : '',
      area_id: selectedAreaId
    });
  };


  return (
    <div className="flex flex-col   h-[calc(100vh-6rem)]   overflow-hidden">
      <div className='flex mb-2 justify-between'>
        <div className="flex mb-2">
          <input
            type="file"
            accept=".csv"
            onChange={handleCsvChange}
            className="border border-gray-300 rounded px-2 py-1 w-64"
          />
          <button onClick={handleUploadCsv} className="bg-blue-600 text-white px-2 py-2 ml-2 rounded">
            Upload CSV
          </button>
        </div>

        <div>
          <button onClick={toggleAddModal} className="bg-blue-600 text-white px-2 py-2 rounded">
            Add Employee
          </button>
        </div>
        <div>
          <button onClick={handleDownloadExcel} className="bg-blue-600 text-white px-4 py-2 rounded">
          Download Excel
        </button>
        </div>
      </div>

        <div className="flex items-center border mb-2 w-1/2 border-gray-300 rounded">
          <input
            type="text"
            value={searchTerm}
            onChange={handleSearchChange}
            placeholder="Search by employee name"
            className="px-2 py-2 w-96"
          />
         
        </div>
       

      {/* tabble data */}

      <div className="w-[calc(100vw-14rem)] overflow-x-auto  h-[calc(100vh-4rem)] overflow-y-auto ">
        <table className="table-auto min-w-[2000px]">
          <thead className="bg-gray-200">
            <tr className="bg-gray-100 text-center">
              <th className="p-4 border-b">S.NO</th>
              <th className="p-4 border-b">Employee Id</th>
              <th className="p-4 border-b">Image</th>
              <th className="p-4 border-b">Name</th>
              <th className="p-4 border-b">Phone</th>
              <th className="p-4 border-b">Email</th>
              <th className="p-4 border-b">Password</th>
              <th className="p-4 border-b">Role</th>
              <th className="p-4 border-b">Gender</th>
              <th className="p-4 border-b">Address</th>
              <th className="p-4 border-b">Area Name</th>
              <th className="p-4 border-b">Area id</th>

              <th className="p-4 border-b">Actions</th>
            </tr>
          </thead>
          <tbody className='text-center'>
            {filteredEmployees.map((employee, index) => (
              <tr key={employee.employee_id}>
                <td className="p-2 border-b ">{index + 1}</td>
                <td className="p-2 border-b ">{employee.employee_id}</td>
                <td className="p-2 border-b text-center"><img src={`https://ramakumaritrades.co.in/storage/userdp/${employee.image}`} alt={employee.employee_name} className="h-10 w-15 object-cover" /></td>
                <td className="p-2 border-b ">{employee.employee_name}</td>
                <td className="p-2 border-b ">{employee.mobile_number}</td>
                <td className="p-2 border-b ">{employee.email}</td>
                <td className="p-2 border-b ">{employee.password}</td>
                <td className="p-2 border-b ">{employee.role}</td>
                <td className="p-2 border-b ">{employee.gender}</td>
                <td className="p-2 border-b ">{employee.Address}</td>
                <td className="p-2 border-b ">{employee.area_name}</td>
                <td className="p-2 border-b ">{employee.area_id}</td>

                <td className=" p-2 border-b ">
                  <button
                    onClick={() => toggleEditModal(employee)}
                    className="text-blue-600 hover:text-blue-800 mx-2"
                  >
                    <FaEdit size={16} /> {/* Edit icon */}
                  </button>
                  <button
                    onClick={() => handleDeleteEmployee(employee.employee_id)}
                    className="text-red-600 hover:text-red-800 mx-2"
                  >
                    <FaTrash size={16} /> {/* Delete icon */}
                  </button>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>

      {/* Add Employee Modal */}
      {showAddModal && (
        <div className="fixed inset-0 bg-gray-800 bg-opacity-50 flex justify-center items-center">
          <div className="bg-white p-6 rounded w-full max-w-3xl h-full max-h-[80vh] overflow-y-auto">
            <h2 className="text-xl mb-4">Add New Employee</h2>
            <form onSubmit={handleAddEmployee}>
              <div className="grid grid-cols-2 gap-4">
                {/* Employee Name */}
                <div>
                  <label>Employee Name:</label>
                  <input
                    type="text"
                    name="employee_name"
                    // value={formData.employee_name}
                    onChange={handleInputChange}
                    required
                    className="border border-gray-300 rounded px-2 py-1 w-full"
                  />
                </div>

                {/* Employee Phone */}
                <div>
                  <label>Phone:</label>
                  <input
                    type="text"
                    name="mobile_number"
                    // value={formData.mobile_number}
                    onChange={handleInputChange}
                    required
                    className="border border-gray-300 rounded px-2 py-1 w-full"
                  />
                </div>

                {/* Employee Email */}
                <div>
                  <label>Email:</label>
                  <input
                    type="email"
                    name="email"
                    // value={formData.email}
                    onChange={handleInputChange}
                    required
                    className="border border-gray-300 rounded px-2 py-1 w-full"
                  />
                </div>


                {/* Employee Password */}
                <div className="relative">
                  <label>Password:</label>
                  <input
                    type={showPassword ? 'text' : 'password'} // Toggle between text and password
                    name="password"
                    // value={formData.password}
                    onChange={handleInputChange}
                    required
                    className="border border-gray-300 rounded px-2 py-1 w-full"
                  />

                  {/* Show/Hide Password Icon */}
                  <span
                    className="absolute right-2 top-8 cursor-pointer"
                    onClick={() => setShowPassword(!showPassword)} // Toggle show/hide state
                  >
                    {showPassword ? <FaEyeSlash /> : <FaEye />} {/* Show different icons */}
                  </span>
                </div>



                {/* Employee Role */}
                <div>
                  <label>Role:</label>
                  <input
                    type="text"
                    name="role"
                    // value={formData.role}
                    onChange={handleInputChange}
                    required
                    className="border border-gray-300 rounded px-2 py-1 w-full"
                  />
                </div>

                <div>
                  <label>Gender:</label>
                  <input
                    type="text"
                    name="gender"
                    // value={formData.gender}
                    onChange={handleInputChange}
                    required
                    className="border border-gray-300 rounded px-2 py-1 w-full"
                  />
                </div>

                {/* Employee Address */}
                <div>
                  <label>Address:</label>
                  <input
                    type="text"
                    name="Address"
                    // value={formData.Address}
                    onChange={handleInputChange}
                    className="border border-gray-300 rounded px-2 py-1 w-full"
                  />
                </div>

                {/* Area ID */}
                <div>
                  <label>Area Name:</label>
                  <select
                    name="area_name"
                    // value={formData.area_id}
                    onChange={handleAreaChange}
                    required
                    className="border border-gray-300 rounded px-2 py-1 w-full"
                  >
                    <option value="" disabled>Select an area</option>
                    {areas.map((area) => (
                      <option key={area.area_id} value={area.area_id}>
                        {area.area_name}
                      </option>
                    ))}
                  </select>
                </div>
                <div>
                  <label>Area ID:</label>
                  <input
                    type="text"
                    name="area_id"
                    value={formData.area_id}
                    onChange={handleInputChange}
                    required
                    className="border border-gray-300 rounded px-2 py-1 w-full"
                    readOnly // Make this field read-only if area_id is selected via dropdown
                  />
                </div>

              </div>

              {/* Employee Image */}
              <div>
                <label>Employee Image:</label>
                <input
                  type="file"
                  name="image"
                  onChange={handleFileChange}
                  className="border border-gray-300 rounded px-2 py-1 w-full"
                />
              </div>
              {/* Add and Cancel Buttons */}
              <div className="mt-4">
                <button
                  type="submit"
                  className="bg-blue-600 text-white px-4 py-2 rounded"
                >
                  Add Employee
                </button>
                <button
                  type="button"
                  onClick={toggleAddModal}
                  className="bg-gray-400 text-white px-4 py-2 rounded ml-4"
                >
                  Cancel
                </button>
              </div>
            </form>
          </div>
        </div>
      )}

      {/* Edit Employee Modal */}
      {showEditModal && (
        <div className="fixed inset-0 bg-gray-800 bg-opacity-50 flex justify-center items-center">
          <div className="bg-white p-6 rounded w-full max-w-3xl h-full max-h-[80vh] overflow-y-auto">
            <h2 className="text-xl mb-4">Edit Employee</h2>
            <form onSubmit={handleEditEmployee}>
              <div className="grid grid-cols-2 gap-4">
                {/* Employee Name */}
                <div>
                  <label>Employee Name:</label>
                  <input
                    type="text"
                    name="employee_name"
                    value={formData.employee_name}
                    onChange={handleInputChange}
                    className="border border-gray-300 rounded px-2 py-1 w-full"
                  />
                </div>

                {/* Employee Phone */}
                <div>
                  <label>Phone:</label>
                  <input
                    type="text"
                    name="mobile_number"
                    value={formData.mobile_number}
                    onChange={handleInputChange}
                    className="border border-gray-300 rounded px-2 py-1 w-full"
                  />
                </div>

                {/* Employee Email */}
                <div>
                  <label>Email:</label>
                  <input
                    type="email"
                    name="email"
                    value={formData.email}
                    onChange={handleInputChange}
                    className="border border-gray-300 rounded px-2 py-1 w-full"
                  />
                </div>

                {/* Employee Password */}
                <div className="relative">
                  <label>Password:</label>
                  <input
                    type={showPassword ? 'text' : 'password'} // Toggle between text and password
                    name="password"
                    value={formData.password}
                    onChange={handleInputChange}
                    required
                    className="border border-gray-300 rounded px-2 py-1 w-full"
                  />

                  {/* Show/Hide Password Icon */}
                  <span
                    className="absolute right-2 top-8 cursor-pointer"
                    onClick={() => setShowPassword(!showPassword)} // Toggle show/hide state
                  >
                    {showPassword ? <FaEyeSlash /> : <FaEye />} {/* Show different icons */}
                  </span>
                </div>



                {/* Employee Role */}
                <div>
                  <label>Role:</label>
                  <input
                    type="text"
                    name="role"
                    value={formData.role}
                    onChange={handleInputChange}
                    className="border border-gray-300 rounded px-2 py-1 w-full"
                  />
                </div>

                {/* Employee Role */}
                <div>
                  <label>Gender:</label>
                  <input
                    type="text"
                    name="gender"
                    value={formData.gender}
                    onChange={handleInputChange}
                    className="border border-gray-300 rounded px-2 py-1 w-full"
                  />
                </div>

                {/* Employee Address */}
                <div>
                  <label>Address:</label>
                  <input
                    type="text"
                    name="Address"
                    value={formData.Address}
                    onChange={handleInputChange}
                    className="border border-gray-300 rounded px-2 py-1 w-full"
                  />
                </div>

                {/* Area ID */}
                <div>
                  <label>Area ID:</label>
                  <input
                    type="text"
                    name="area_id"
                    value={formData.area_id}
                    onChange={handleInputChange}
                    className="border border-gray-300 rounded px-2 py-1 w-full"
                  />
                </div>

                {/* Employee Image */}
                <div>
                  <label>Employee Image:</label>
                  <input
                    type="file"
                    name="image"
                    onChange={handleFileChange}
                    className="border border-gray-300 rounded px-2 py-1 w-full"
                  />
                </div>

                {/* Area Name */}
                <div>
                  <label>Area Name:</label>
                  <input
                    type="text"
                    name="area_name"
                    value={formData.area_name}
                    onChange={handleInputChange}
                    className="border border-gray-300 rounded px-2 py-1 w-full"
                  />
                </div>
              </div>

              {/* Save and Cancel Buttons */}
              <div className="mt-4">
                <button
                  type="submit"
                  className="bg-blue-600 text-white px-4 py-2 rounded"
                >
                  Save Changes
                </button>
                <button
                  type="button"
                  onClick={() => toggleEditModal(null)}
                  className="bg-gray-400 text-white px-4 py-2 rounded ml-4"
                >
                  Cancel
                </button>
              </div>
            </form>
          </div>
        </div>
      )}


    </div>
  );
}

export default EmployeePage;
