import React, { useState, useEffect } from "react";
import { FaEdit, FaTrash } from "react-icons/fa";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import jsPDF from "jspdf";
import "jspdf-autotable";

const initialFormData = {
  customer_name: "",
  employee_name: "",
  total_price: "",
  mode_of_payment: "",
  delivery_date: "",
  status: "",
};

const OrderList = () => {
  const [orders, setOrders] = useState([]);
  const [showEditModal, setShowEditModal] = useState(false);
  const [formData, setFormData] = useState(initialFormData);
  const [selectedOrder, setSelectedOrder] = useState(null);
  const navigate = useNavigate();
  const token = localStorage.getItem("accessToken");

  // Fetch all orders
  const fetchOrders = async () => {
    if (!token) {
      console.error("Authorization token is missing!");
      return;
    }

    try {
      const response = await axios.get(
        "https://ramakumaritrades.co.in/web/order/get-all-order",
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      );
      const data = response.data;
      setOrders(response.data.data || []);
      localStorage.setItem(
        "order_id",
        JSON.stringify(data.data.map((orders) => orders.order_id))
      );
    } catch (error) {
      console.error("Error fetching orders:", error.message);
    }
  };

  useEffect(() => {
    fetchOrders();
  }, []);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const toggleEditModal = (order) => {
    if (order) {
      setSelectedOrder(order);

      const deliveryDate = order.delivery_date
        ? new Date(order.delivery_date)
        : null;

      const formattedDate =
        deliveryDate && !isNaN(deliveryDate)
          ? deliveryDate.toISOString().split("T")[0]
          : "";

      setFormData({
        ...order,
        delivery_date: formattedDate,
      });
    } else {
      setFormData(initialFormData);
    }
    setShowEditModal(!showEditModal);
  };

  const handleEditOrder = async (e) => {
    e.preventDefault();
  
    try {
      // Create a new FormData object to send the updated fields
      const formDataToSend = new FormData();
  
      // Append each field from the formData object to formDataToSend
      if (formData.delivery_date) formDataToSend.append('delivery_date', formData.delivery_date);
      if (formData.status) formDataToSend.append('status', formData.status);
      if (formData.mode_of_payment) formDataToSend.append('mode_of_payment', formData.mode_of_payment);
      if (formData.total_price) formDataToSend.append('total_price', formData.total_price);
  
      // Always include the order_id in the update
      formDataToSend.append('order_id', selectedOrder.order_id);
  
      // Send the request to update the order
      const response = await fetch("https://ramakumaritrades.co.in/web/order/update-orders", {
        method: 'PUT',
        body: formDataToSend, // Send formDataToSend here
        headers: {
          'Authorization': `Bearer ${token}` // Authorization header for authentication
        },
      });
  
      if (!response.ok) {
        const errorData = await response.json();
        throw new Error(errorData.message || 'Failed to update order.');
      }
  
      const data = await response.json();
  
      if (data.success) {
        // Update state to reflect the changes in the orders list
        setOrders((prevOrders) =>
          prevOrders.map((order) =>
            order.order_id === selectedOrder.order_id
              ? {
                  ...order,
                  delivery_date: formData.delivery_date || order.delivery_date,
                  status: formData.status || order.status,
                  mode_of_payment: formData.mode_of_payment || order.mode_of_payment,
                  total_price: formData.total_price || order.total_price,
                }
              : order
          )
        );
  
        toggleEditModal(false); // Close the modal
      }
    } catch (error) {
      console.error("Error updating order:", error.message);
    }
  };
  

  const handleDeleteOrder = async (order_id) => {
    if (!order_id) {
      console.error("Invalid order_id:", order_id);
      return;
    }

    if (window.confirm("Are you sure you want to delete this order?")) {
      try {
        const response = await axios.delete(
          "https://ramakumaritrades.co.in/web/order/delete-order-by-id",
          {
            data: { order_id },
            headers: { Authorization: `Bearer ${token}` },
          }
        );

        if (response.data.success) {
          setOrders((prevOrders) =>
            prevOrders.filter((order) => order.order_id !== order_id)
          );
        } else {
          console.error("Failed to delete order:", response.data.message);
        }
      } catch (error) {
        console.error("Error deleting order:", error);
      }
    }
  };

  const handleViewInvoice = (order) => {
    if (order?.order_id) {
      navigate(`/invoice/${order.order_id}`);
    } else {
      console.error("Invalid order ID:", order);
    }
  };

  const downloadPDF = () => {
    const doc = new jsPDF();
    doc.text("Order List", 14, 10);
    doc.autoTable({
      head: [
        [
          "Order ID",
          "Customer Name",
          "Employee Name",
          "Total Price",
          "Mode Of Payment",
          "Delivery Date",
          "Status",
        ],
      ],
      body: orders.map((order) => [
        order.order_id,
        order.customer_name,
        order.employee_name,
        order.total_price,
        order.mode_of_payment,
        order.delivery_date,
        order.status,
      ]),
    });
    doc.save("order-list.pdf");
  };

  return (
    <div className="flex flex-col  h-[calc(100vh-6rem)] overflow-hidden">
      <div className="flex justify-between mb-4">
        <h2 className="text-2xl font-semibold mb-4">Order List</h2>
        <button
          onClick={downloadPDF}
          className="bg-blue-600 text-white px-4 py-2 rounded"
        >
          Download Pdf
        </button>
      </div>

      <div className="w-[calc(100vw-14rem)] overflow-x-auto h-[calc(100vh-4rem)] overflow-y-auto ">
        <table className="table-auto min-w-[1500px]">
          <thead>
            <tr className="bg-gray-100 text-left">
              <th className="p-4 border-b">Order ID</th>
              <th className="p-4 border-b">Customer Name</th>
              <th className="p-4 border-b">Employee Name</th>
              <th className="p-4 border-b">Total Price</th>
              <th className="p-4 border-b">Mode Of Payment</th>
              <th className="p-4 border-b">Date of Order</th>
              <th className="p-4 border-b">Delivery Date</th>
              <th className="p-4 border-b">Status</th>
              <th className="p-4 border-b">Quotation</th>
              <th className="p-4 border-b">Actions</th>
            </tr>
          </thead>
          <tbody>
            {orders.length > 0 ? (
              orders.map((order) => (
                <tr key={order.order_id} className="hover:bg-gray-50">
                  <td className="p-2 border-b">{order.order_id}</td>
                  <td className="p-2 border-b">{order.customer_name}</td>
                  <td className="p-2 border-b">{order.employee_name}</td>
                  <td className="p-2 border-b">₹{order.total_price}</td>
                  <td className="p-2 border-b">{order.mode_of_payment}</td>
                  <td className="p-2 border-b">{order.createdAt}</td>
                  <td className="p-2 border-b">{order.delivery_date}</td>
                  <td className="p-2 border-b">{order.status}</td>
                  <td className="p-2 border-b">
                    <button
                      onClick={() => handleViewInvoice(order)}
                      className="text-blue-500 underline"
                    >
                      View Quotation
                    </button>
                  </td>
                  <td className="p-2 border-b ">
                    <button
                      onClick={() => toggleEditModal(order)}
                      className="text-blue-500 mx-2"
                    >
                      <FaEdit />
                    </button>
                    <button
                      onClick={() => handleDeleteOrder(order.order_id)}
                      className="text-red-500 mx-2"
                    >
                      <FaTrash />
                    </button>
                  </td>
                </tr>
              ))
            ) : (
              <tr>
                <td className="p-2 border-b text-center" colSpan="9">
                  No orders available
                </td>
              </tr>
            )}
          </tbody>
        </table>
      </div>

      {showEditModal && (
        <div className="fixed inset-0 bg-gray-800 bg-opacity-50 flex justify-center items-center">
          <div className="bg-white p-6 rounded w-full max-w-3xl h-full max-h-[80vh] overflow-y-auto">
            <h2 className="text-xl mb-4">Edit Order</h2>
            <form onSubmit={handleEditOrder}>
              <div className="grid grid-cols-2 gap-4">
                {/* Form fields */}
                <div className="col-span-1">
                  <label>Customer Name:</label>
                  <input
                    type="text"
                    name="customer_name"
                    value={formData.customer_name || ""}
                    onChange={handleInputChange}
                    className="border rounded w-full px-2 py-1"
                  />
                </div>
                <div className="col-span-1">
                  <label>Employee Name:</label>
                  <input
                    type="text"
                    name="employee_name"
                    value={formData.employee_name || ""}
                    onChange={handleInputChange}
                    className="border rounded w-full px-2 py-1"
                  />
                </div>
                {/* <div className="col-span-1">
                  <label>Total Price:</label>
                  <input
                    type="text"
                    name="total_price"
                    value={formData.total_price || ""}
                    onChange={handleInputChange}
                    className="border rounded w-full px-2 py-1"
                  />
                </div> */}
                <div className="col-span-1">
                  <label>Mode Of Payment:</label>
                  <input
                    type="text"
                    name="mode_of_payment"
                    value={formData.mode_of_payment || ""}
                    onChange={handleInputChange}
                    className="border rounded w-full px-2 py-1"
                  />
                </div>
                <div className="col-span-1">
                  <label>Delivery Date:</label>
                  <input
                    type="date"
                    name="delivery_date"
                    value={formData.delivery_date || ""}
                    onChange={handleInputChange}
                    className="border rounded w-full px-2 py-1"
                  />
                </div>
                <div className="col-span-1">
                  <label>Status:</label>
                  <select
                    name="status"
                    value={formData.status || ""}
                    onChange={handleInputChange}
                    required
                    className="border border-gray-300 rounded px-2 py-1 w-full"
                  >
                    <option value="" disabled>Select a status</option>
                    <option value="Pending">Pending</option>
                    <option value="Shipped">Shipped</option>
                    <option value="Delivered">Delivered</option>
                    <option value="Processing">Processing</option>
                  </select>
                </div>
                
                     </div>
              <div className="mt-4 flex justify-end gap-4">
                <button
                  type="button"
                  onClick={() => toggleEditModal(null)}
                  className="bg-gray-500 text-white px-4 py-2 rounded"
                >
                  Cancel
                </button>
                <button
                  type="submit"
                  className="bg-blue-500 text-white px-4 py-2 rounded"
                >
                  Save Changes
                </button>
              </div>
            </form>
          </div>
        </div>
      )}
    </div>
  );
};

export default OrderList;
